// OrderPage.js
import React, { useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import './OrderPage.css';

const OrderPage = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const { serviceDetails, item } = location.state || {};
  const [copiedTemplate, setCopiedTemplate] = useState(false);

  if (!serviceDetails) {
    return <div className="order-page loading">Loading...</div>;
  }

  console.log(serviceDetails)
  const { name, orderPlatforms = [], category = '', hasServilliaDiscount } = serviceDetails;
  
  // Determine if we're booking or ordering based on category
  const isBooking = ['hair','salon', 'spa', 'consultation', 'appointment', 'class', 'event', 'reservation','other']
    .some(term => category.toLowerCase().includes(term));
  
  const actionVerb = isBooking ? 'booking' : 'ordering';
  const pageTitle = isBooking ? 'How to Book with' : 'How to Order from';
  
  const getMessageTemplate = (platform, itemName) => {
    const actionText = isBooking ? `booking ${itemName || 'your services'}` : `${itemName || 'your services'}`;
    return `Hi, I'm reaching out from Servillia and I'm interested in ${actionText}. I understand that customers from Servillia may be eligible for special discounts. Could you please provide more information?`;
  };

  const handleCopyTemplate = (template) => {
    navigator.clipboard.writeText(template);
    setCopiedTemplate(true);
    setTimeout(() => setCopiedTemplate(false), 2000);
  };

  const getActionUrl = (platform) => {
    switch (platform.type) {
      case 'website':
        return platform.url;
      case 'whatsapp':
        const whatsappTemplate = getMessageTemplate(platform, item?.name);
        return `https://wa.me/${platform.phoneNumber}?text=${encodeURIComponent(whatsappTemplate)}`;
      case 'instagram':
        return `https://www.instagram.com/${platform.username}`;
      case 'facebook':
        return `https://m.me/${platform.username}`;
      case 'snapchat':
        return `https://www.snapchat.com/add/${platform.username}`;
      default:
        return '#';
    }
  };

  const getPlatformIcon = (type) => {
    switch (type) {
      case 'website':
        return '🌐';
      case 'whatsapp':
        return '📱';
      case 'instagram':
        return '📸';
      case 'facebook':
        return '👍';
      case 'snapchat':
        return '👻';
      default:
        return '📞';
    }
  };

  return (
    <div className="order-page">
      <h1>{pageTitle} {name}</h1>
      {item?.name && <p className="item-info">You are {actionVerb}: <span>{item.name} ${item.price}</span></p>}

      {orderPlatforms.length > 0 ? (
        <div className="order-methods">
          <h2>Contact the Provider</h2>
          {hasServilliaDiscount && (
            <p className="discount-note">
              Remember to mention you're from Servillia to receive special discounts!
            </p>
          )}
          
          <div className="platform-grid">
            {orderPlatforms.map((platform, index) => {
              const messageTemplate = getMessageTemplate(platform, item?.name);
              const actionUrl = getActionUrl(platform);
              
              return (
                <div key={index} className="platform-card">
                  <div className="platform-icon">{getPlatformIcon(platform.type)}</div>
                  <h3>{platform.type.charAt(0).toUpperCase() + platform.type.slice(1)}</h3>
                  <p>The provider prefers to be contacted through {platform.type}.</p>
                  
                  <div className="template-section">
                    <h4>Message Template:</h4>
                    <div className="template-box">
                      <p>{messageTemplate}</p>
                      <button 
                        className="copy-button"
                        onClick={() => handleCopyTemplate(messageTemplate)}
                      >
                        {copiedTemplate ? "Copied!" : "Copy"}
                      </button>
                    </div>
                  </div>
                  
                  <a 
                    href={actionUrl}
                    target="_blank" 
                    rel="noopener noreferrer"
                    className="contact-button"
                  >
                    {platform.type === 'website' 
                      ? 'Visit Website' 
                      : `Contact via ${platform.type.charAt(0).toUpperCase() + platform.type.slice(1)}`}
                  </a>
                  
                  {platform.type === 'whatsapp' && platform.phoneNumber && (
                    <p className="contact-detail">Phone: {platform.phoneNumber}</p>
                  )}
                  {['instagram', 'facebook', 'snapchat'].includes(platform.type) && platform.username && (
                    <p className="contact-detail">Username: @{platform.username}</p>
                  )}
                </div>
              );
            })}
          </div>
        </div>
      ) : (
        <p className="no-platforms">This provider has not specified order platforms. Please contact them directly.</p>
      )}

      <button className="go-back-button" onClick={() => navigate(-1)}>
        Go Back
      </button>
    </div>
  );
};

export default OrderPage;