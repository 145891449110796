import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useLocation } from "react-router-dom";
import { db } from "../firebase"; // Firebase import
import { collection, query, where, getDocs, doc, getDoc } from "firebase/firestore";
import { getAuth, onAuthStateChanged } from "firebase/auth"; // Firebase auth methods

import "./Header.css";

import PinIcon from "../assets/icons/map.png";
import HomeIcon from "../assets/icons/home.png";
import GlobeIcon from "../assets/icons/continent.png";
import Search from "../assets/icons/magnifying-glass.png";
import Profile from "../assets/icons/user.png";

function Header({
  searchQuery,
  setSearchQuery,
  handleSearchSubmit,
  handleSearchChange,
  handleCategoryChange,
  activeCategory,
  userAddress,
  setShowModal,
  toggleDarkMode,
  isDarkMode,
  name,
  city,
}) {
  const [menuOpen, setMenuOpen] = useState(false);
  const [searchResults, setSearchResults] = useState([]);
  const [noResultsMessage, setNoResultsMessage] = useState("");
  const [user, setUser] = useState(null);
  const [isProvider, setIsProvider] = useState(false);
  const [hasNoPlan, setHasNoPlan] = useState(false); // Track plan status

  const navigate = useNavigate();
  const location = useLocation();
  const continentName = location.state?.continent;

  useEffect(() => {
    const auth = getAuth();
    const unsubscribe = onAuthStateChanged(auth, async (currentUser) => {
      if (currentUser) {
        setUser(currentUser);
        await checkProviderStatus(currentUser.uid);
      } else {
        setUser(null);
        setIsProvider(false);
        setHasNoPlan(false);
      }
    });

    return () => unsubscribe();
  }, []);

  const checkProviderStatus = async (userId) => {
    try {
      // Fetch user document
      const userRef = doc(db, "users", userId);
      const userSnap = await getDoc(userRef);

      if (userSnap.exists() && userSnap.data().isProvider) {
        setIsProvider(true);

        // Fetch provider document
        const providerRef = doc(db, "providers", userId);
        const providerSnap = await getDoc(providerRef);

        if (providerSnap.exists() && providerSnap.data().plan === "none") {
          setHasNoPlan(true);
        } else {
          setHasNoPlan(false);
        }
      } else {
        setIsProvider(false);
        setHasNoPlan(false);
      }
    } catch (error) {
      console.error("Error fetching provider details:", error);
    }
  };

  useEffect(() => {
    if (searchQuery) {
      const fetchSearchResults = async () => {
        const servicesRef = collection(db, "services");
        const q = query(servicesRef, where("continent", "==", continentName.toLowerCase()));
        const querySnapshot = await getDocs(q);
        const results = [];

        querySnapshot.forEach((doc) => {
          const data = doc.data();
          if (data.name.toLowerCase().startsWith(searchQuery.toLowerCase())) {
            results.push({ id: doc.id, ...data });
          }
        });

        setSearchResults(results);
        if (results.length === 0) {
          setNoResultsMessage(`No result for "${searchQuery}"`);
        } else {
          setNoResultsMessage("");
        }
      };

      fetchSearchResults();
    } else {
      setSearchResults([]);
      setNoResultsMessage("");
    }
  }, [searchQuery, continentName]);

  const handleResultClick = (serviceId) => {
    navigate(`/service/${serviceId}`);
    setSearchQuery(""); // Clear search query after navigating
  };

  const handleBurgerClick = () => {
    setMenuOpen(!menuOpen);
  };

  const navigateToContinent = () => {
    if (continentName?.toLowerCase() === "africa") {
      navigate("/continent/africa", { state: { name, continent: continentName, city } });
    } else if (continentName?.toLowerCase() === "asia") {
      navigate("/continent/asia", { state: { name, continent: continentName, city } });
    }
  };

  const goToContinentSelect = () => {
    navigate("/select-continent", { state: { name, city } });
  };

  const openProfile = () => {
    navigate("/user-profile");
  };

  const handleLogin = () => {
    navigate("/login"); // Navigate to the login page
  };

  return (
    <header className="header">
      <div className="top">
        <div className="logo" onClick={navigateToContinent} style={{ cursor: "pointer" }}>
          <img src="/logo.png" alt="logo" />
          <span>Servillia</span>
        </div>

        {/* Burger Icon */}
        <div className={`burger-menu ${menuOpen ? "open" : ""}`} onClick={handleBurgerClick}>
          <div className="burger-bar"></div>
          <div className="burger-bar"></div>
          <div className="burger-bar"></div>
        </div>
      </div>

      <form className="search-bar" onSubmit={handleSearchSubmit}>
        <div className="search-input-wrapper">
          <img src={Search} alt="Pin Icon" className="header-icon" />
          <input
            type="text"
            placeholder="Search Servillia"
            value={searchQuery}
            onChange={handleSearchChange}
            className="search-input"
          />
          {searchQuery && <span className="clear-icon" onClick={() => setSearchQuery("")}>✖</span>}
        </div>
        {/* Display search results or no results message */}
        {searchQuery && (
          <div className="search-results">
            {searchResults.length > 0 ? (
              searchResults.map((result, index) => (
                <div key={index} className="search-result-item" onClick={() => handleResultClick(result.id)}>
                  <img src={result.logo} alt={result.name} className="search-result-image" />
                  <div>
                    <h4>{result.name}</h4>
                    <p>{result.description}</p>
                  </div>
                </div>
              ))
            ) : (
              <div className="no-results">{noResultsMessage}</div>
            )}
          </div>
        )}
      </form>

      <div className={`header-actions ${menuOpen ? "open" : ""}`}>
        <div className="header-action-item address" onClick={() => setShowModal(true)}>
          <img src={PinIcon} alt="Pin Icon" className="header-icon" /> {userAddress || "Enter Address"}
        </div>
        <button className="header-button" onClick={() => navigate("/")}>
          <img src={HomeIcon} alt="Home Icon" className="header-icon" /> Homepage
        </button>
        <button className="header-button" onClick={goToContinentSelect}>
          <img src={GlobeIcon} alt="Globe Icon" className="header-icon" /> Continent
        </button>

        {/* Show "Activate Subscription" button only if the user is a provider and has no plan */}
        {isProvider && hasNoPlan && (
          <button className="header-button" onClick={() => navigate("/pricing")}>
            Activate Subscription
          </button>
        )}

        {user ? (
          <img src={Profile} alt="Profile Icon" className="header-profile-icon" onClick={openProfile} style={{ cursor: "pointer", marginLeft: "10px" }} />
        ) : (
          <button className="header-login-button" onClick={handleLogin}>Login</button>
        )}
      </div>
    </header>
  );
}

export default Header;
