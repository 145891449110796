import React, { useState } from 'react';
import { getAuth, createUserWithEmailAndPassword } from "firebase/auth";
import { getFirestore, doc, setDoc, getDoc, updateDoc, arrayUnion } from "firebase/firestore";
import { query, where, getDocs, collection } from "firebase/firestore";
import { v4 as uuidv4 } from "uuid";
import emailjs from 'emailjs-com';
import { useNavigate } from 'react-router-dom';
import { getStorage, ref, uploadBytes, getDownloadURL } from "firebase/storage"; // Firebase storage imports
import './BecomeServiceProvider.css';
import countryCityData from '../../countryCityData.json';
import { auth, db } from '../../firebase';

const BecomeServiceProvider = () => {
  const [step, setStep] = useState(1);
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    businessName: '',
    category: '',
    otherCategory: '',
    city: '',
    country: 'Canada',
    serviceDescription: '',
    website: '',
    locations: [{country: 'Canada', city: '', address: ''}], // multiple locations are allowed
    orderMethods: [],
    logo: null,
    banner: null,
    additionalImages: [],
    servicesAndPrices: [],
  });


  const uploadImages = async (providerId, businessName) => {
    // Convert business name to a safe folder name (remove spaces & special chars)
    const safeBusinessName = businessName.replace(/[^a-zA-Z0-9]/g, "-").toLowerCase();
  
    // Define base path for this business
    const basePath = `businesses/${safeBusinessName}`;
  
    // Helper function to upload a file and return URL
    const uploadFile = async (file, path) => {
      if (!file) return null; // Skip if no file is provided
      const storageRef = ref(getStorage(), path);
      await uploadBytes(storageRef, file);
      return await getDownloadURL(storageRef);
    };
  
    // Upload images into organized folders
    const urls = {
      logoUrl: await uploadFile(formData.logo, `${basePath}/logo.png`),
      bannerUrl: await uploadFile(formData.banner, `${basePath}/banner.png`),
    };
  
    // Upload additional images to a subfolder
    const additionalImageUrls = await Promise.all(
      formData.additionalImages.map((img, index) =>
        uploadFile(img, `${basePath}/images/image-${index + 1}.png`)
      )
    );
    urls.additionalImageUrls = additionalImageUrls.filter(url => url !== null); // Remove null values
  
    return urls;
  };
  

  const [errors, setErrors] = useState({});
  const navigate = useNavigate();
  const totalSteps = 6;

  const validateStep = () => {
    let validationErrors = {};
    if (step === 1) {
      if (!formData.name) validationErrors.name = 'Name is required';
      if (!formData.email) validationErrors.email = 'Email is required';
    }
    if (step === 2) {
      if (!formData.businessName) validationErrors.businessName = 'Business name is required';
      if (!formData.category) validationErrors.category = 'Category is required';
      if (formData.category === 'Other' && !formData.otherCategory) validationErrors.otherCategory = 'Specify other category';
      if (!formData.serviceDescription) validationErrors.serviceDescription = 'Service description is required';
    }
    if (step === 3) {
      if (!formData.locations) validationErrors.city = 'Loaction required';
    }
    if (step === 4) {
      if (formData.orderMethods.length === 0) validationErrors.orderMethods = 'Please add at least one order/booking method';
    }

    setErrors(validationErrors);
    return Object.keys(validationErrors).length === 0;
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleLocationChange = (index, field, value) => {
    const updatedLocations = formData.locations.map((location, i) =>
      i === index ? {...location, [field]: value} : location
    );
    setFormData({...formData, locations: updatedLocations})
  };

  const addLocation = () => {
    setFormData({
      ...formData,
      locations: [...formData.locations, {country:'', city:'', address:''}]
    });
  };

  const removeLocation = (index) => {
    const updatedLocations = formData.locations.filter((_,i) => i !== index);
    setFormData({...formData, locations: updatedLocations})
  }

  // Function to add a new order method field
  const addOrderMethod = () => {
    setFormData({
      ...formData,
      orderMethods: [...formData.orderMethods, { method: '', type: '', username: '' }],
    });
  };

  // Function to handle changes in each order method's fields
  const handleOrderMethodChange = (index, field, value) => {
    const updatedOrderMethods = formData.orderMethods.map((orderMethod, i) => 
      i === index ? { ...orderMethod, [field]: value } : orderMethod
    );
    setFormData({ ...formData, orderMethods: updatedOrderMethods });
  };

  // Function to remove an order method
  const removeOrderMethod = (index) => {
    const updatedOrderMethods = formData.orderMethods.filter((_, i) => i !== index);
    setFormData({ ...formData, orderMethods: updatedOrderMethods });
  };

  const handleFileChange = (e) => {
    const { name, files } = e.target;
    setFormData({ ...formData, [name]: files[0] });
  };

  const handleAdditionalImagesChange = (e) => {
    const { files } = e.target;
    setFormData({ ...formData, additionalImages: Array.from(files) });
  };

  const handleAddService = () => {
    setFormData({
      ...formData,
      servicesAndPrices: [...formData.servicesAndPrices, { name: '', price: '' }],
    });
  };

  const handleServiceChange = (index, field, value) => {
    const updatedServices = formData.servicesAndPrices.map((service, i) =>
      i === index ? { ...service, [field]: value } : service
    );
    setFormData({ ...formData, servicesAndPrices: updatedServices });
  };

  const removeService = (index) => {
    const updatedServices = formData.servicesAndPrices.filter((_, i) => i !== index);
    setFormData({ ...formData, servicesAndPrices: updatedServices });
  };

  const nextStep = () => {
    if (validateStep()) {
      setStep(step + 1);
      window.scrollTo(0, 0);
    }
  };

  const prevStep = () => {
    setStep(step - 1);
    window.scrollTo(0, 0);
  };

  const userExists = async (email) => {
    const usersCollectionRef = collection(db, "users");
    console.log(email);
    const q = query(usersCollectionRef, where("email", "==", email));
    
    console.log(q);

    const querySnapshot = await getDocs(q);
    if (!querySnapshot.empty) {
      const userDoc = querySnapshot.docs[0]; // Get the first matching document
      return { exists: true, uid: userDoc.id, data: userDoc.data() };
    }
    return { exists: false };
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    
    if (validateStep()) {
      let providerId = null; // Variable to store provider ID

      // Update provider info first 
      const password = uuidv4().slice(0, 12); // Generate a secure random password
    
      try {
        // Check if user already existis in our database
        const userCheck = await userExists(formData.email);

        if (userCheck.exists) {
          console.log("User already exists as a regular user");

          // Retrieve the UID of the existing user from their document
          const userUid = userCheck.uid; // The UID is the document ID in the 'users' collection
          providerId = userUid; // Set providerId to existing user UID

          const userDocRef = doc(db, "users", userUid);
    
          // Add the user to the providers collection
          const providerDocRef = doc(db, "providers", userUid);
          const providerDoc = await getDoc(providerDocRef);

          if (providerDoc.exists()) {
            console.log("Provider account already exists. Proceed with service creation via the appropriate section.");
            alert("Provider account already exists. Service will be added to this account.");
          } 
          else {
            // Create the provider document if it doesn't already exist
            await setDoc(providerDocRef, {
              name: formData.name,
              email: formData.email,
              approved: false, // Default to false until reviewed
            },{ merge: true }); // Merge to avoid overwriting existing data
      
            console.log("User added to providers collection");
      
            // Update the user's Firestore document to indicate they are now a provider
            await updateDoc(userDocRef, {
              isProvider: true, // Add a flag for provider status
            });
      
            console.log("User document updated with provider status");

            // // Send an email notification about the provider account
            // const templateParams = {
            //   name: formData.name,
            //   email: formData.email,
            //   businessName: formData.businessName,
            // };
      
            // await emailjs.send("service_moljiqm", "template_v1qzaqu", templateParams, "op_sPUaDGFLLXs5Ei");
            // console.log("Email sent successfully");
      
            alert("Your provider status has been added to your account. Please check your email for details.");
          }//
        } 
        else {
          console.log("User does not exist. Creating a new account...");

          // Create a new provider account in Firebase Authentication
          const userCredential = await createUserWithEmailAndPassword(auth, formData.email, password);
          const providerUid = userCredential.user.uid;
          providerId = providerUid; // Set providerId to newly created user UID

          // Add the user to the Firestore 'users' collection
          await setDoc(doc(db, "users", providerUid), {
            firstName: formData.name,
            email: formData.email,
            isProvider: true, // Mark as provider
            createdAt: new Date(),
          });

          // Add the user to the providers collection
          const providerDocRef = doc(db, "providers", providerUid);
          await setDoc(providerDocRef, {
            name: formData.name,
            email: formData.email,
            approved: false, // Default to false until reviewed
            serviceIds: [],
            plan: 'none',
          });

          console.log("User added to Firestore users and providers collections");
      
          //Send an email to the provider with their account details
          const templateParams = {
            name: formData.name,
            email: formData.email,
            password,
            businessName: formData.businessName,
          };
      
          await emailjs.send("service_moljiqm", "template_v1qzaqu", templateParams, "op_sPUaDGFLLXs5Ei");
          console.log("Email sent successfully");
          alert("Service request submitted successfully. Please check your email for account details.");
        }

      } catch (error) {
        console.error("Error creating provider or service:", error);
        alert("An error occurred while submitting your request. Please try again.");
      }

      try {
        // Upload images to Firebase Storage and get URLs
        const uploadedImages = await uploadImages(providerId, formData.businessName);
  

          const serviceDocRef = doc(collection(db, "services"));
          const serviceData = {
            name: formData.businessName,
            category: formData.category.toLowerCase(),
            description: formData.serviceDescription,
            locations: formData.locations,
            approved: false,
            orderPlatforms: formData.orderMethods,
            continent: "TBD",
            providerId: providerId,
            clickCount: 0,
            // services: servicesFormatted,
            logo: uploadedImages.logoUrl || 'https://via.placeholder.com/300x200',
            banner: uploadedImages.bannerUrl || 'https://via.placeholder.com/300x200',
            businessImages: uploadedImages.additionalImageUrls || [],
            createdAt: new Date(),
          };

          console.log('Service data.....')
          console.log(serviceData);

          await setDoc(serviceDocRef,serviceData);

          console.log("Service added successfully!");

          // Add the service ID to the provider's document
          const providerDocRef = doc(db, "providers", providerId);
          await updateDoc(providerDocRef, {
            serviceIds: arrayUnion(serviceDocRef.id), // Add the service ID to the serviceIds array
          });

      console.log("Service ID added to provider document!");

      // Add service request to `service_requests` collection
      await setDoc(doc(db, "service_requests", serviceDocRef.id), {
        ...serviceData, // Copy all service details
        serviceId: serviceDocRef.id, // Store service ID
        providerEmail: formData.email,
        providerName: formData.name,
        requestedAt: new Date(), // Timestamp for tracking
      });
          alert("Your service has been successfully added.");
          navigate("/submission-success");


        //To send email to ME:

        // // Prepare order methods and services/prices as strings
        // const orderMethodsFormatted = formData.orderMethods
        //   .map((om) => `${om.method}: ${om.details}`)
        //   .join(', ');
  
        // const servicesFormatted = formData.servicesAndPrices
        //   .map((sp) => `${sp.name}: $${sp.price}`)
        //   .join(', ');
  
        // // Prepare all data for the email template
        // const templateParams = {
        //   name: formData.name,
        //   email: formData.email,
        //   businessName: formData.businessName,
        //   category: formData.category === 'Other' ? formData.otherCategory : formData.category,
        //   city: formData.city,
        //   country: formData.country,
        //   serviceDescription: formData.serviceDescription,
        //   website: formData.website || 'Not provided',
        //   locations: formData.locations || 'Not provided',
        //   orderMethods: orderMethodsFormatted || 'Not provided',
        //   servicesAndPrices: servicesFormatted || 'Not provided',
        //   // logoUrl: uploadedImages.logoUrl || 'No logo uploaded',
        //   // bannerUrl: uploadedImages.bannerUrl || 'No banner uploaded',
        //   // additionalImages: uploadedImages.additionalImageUrls.length
        //   //   ? uploadedImages.additionalImageUrls.join(', ')
        //   //   : 'No additional images uploaded',
        // };
  
        // // Send email using EmailJS
        // emailjs
        //   .send('service_moljiqm', 'template_pch9mg8', templateParams, 'op_sPUaDGFLLXs5Ei')
        //   .then((response) => {
        //     alert('Request submitted successfully!');
        // //   })
        //   .catch((error) => {
        //     console.error('Failed to send request:', error);
        //   });
      } catch (error) {
        console.error('Error uploading images or sending email:', error);
        alert('An error occurred while submitting your request. Please try again.');
      }
    }

  };
  

  const renderStep = () => {
    switch (step) {
      case 1:
        return (
          <div className="form-section">
            <h3>Personal Information</h3>
            <label>
              Name:
              <input
                type="text"
                name="name"
                value={formData.name}
                onChange={handleChange}
                placeholder="Enter your full name"
                required
              />
              {errors.name && <span className="error-message">{errors.name}</span>}
            </label>
            <label>
              Email:
              <input
                type="email"
                name="email"
                value={formData.email}
                onChange={handleChange}
                placeholder="Enter your email address"
                required
              />
              {errors.email && <span className="error-message">{errors.email}</span>}
            </label>
            <button type="button" className="next-button" onClick={nextStep}>Next</button>
          </div>
        );
      case 2:
        return (
          <div className="form-section">
            <h3>Business Information</h3>
            <label>
              Business Name:
              <input
                type="text"
                name="businessName"
                value={formData.businessName}
                onChange={handleChange}
                placeholder="Enter your business name"
                required
              />
              {errors.businessName && <span className="error-message">{errors.businessName}</span>}
            </label>
            <label>
              Category:
              <select
                name="category"
                value={formData.category}
                onChange={handleChange}
                required
              >
                <option value="">Select a category</option>
                <option value="Food">Food</option>
                <option value="Hair">Hair</option>
                <option value="Local Businesses">Local Businesses</option>
                <option value="Other">Other</option>
              </select>
              {errors.category && <span className="error-message">{errors.category}</span>}
            </label>
            {formData.category === 'Other' && (
              <label>
                Please specify:
                <input
                  type="text"
                  name="otherCategory"
                  value={formData.otherCategory}
                  onChange={handleChange}
                  placeholder="Specify your business category"
                  required
                />
                {errors.otherCategory && <span className="error-message">{errors.otherCategory}</span>}
              </label>
            )}
            <label>
              Service Description:
              <textarea
                name="serviceDescription"
                value={formData.serviceDescription}
                onChange={handleChange}
                placeholder="Describe the services your business provides"
                required
              />
              {errors.serviceDescription && <span className="error-message">{errors.serviceDescription}</span>}
            </label>
            <div className="navigation-buttons">
              <button type="button" className="prev-button" onClick={prevStep}>Back</button>
              <button type="button" className="next-button" onClick={nextStep}>Next</button>
            </div>
          </div>
        );
        case 3:
          return (
            <div className="form-section">
              <h3>Location Information</h3>
              {formData.locations.map((location, index) => (
                <div key={index} className="location-group">
                  <label>
                    Country:
                    <select
                      value={location.country}
                      onChange={(e) => handleLocationChange(index, 'country', e.target.value)}
                      disabled
                      title="Currently, only Canada is available as a country option."
                      required
                    >
                      <option value="Canada">Canada</option>
                    </select>
                  </label>
                  <label>
                    City:
                    <select
                      value={location.city}
                      onChange={(e) => handleLocationChange(index, 'city', e.target.value)}
                      required
                    >
                      <option value="">Select a city</option>
                      {countryCityData["Canada"].sort().map((city) => (
                        <option key={city} value={city}>
                          {city}
                        </option>
                      ))}
                    </select>
                  </label>
                  <label>
                    Address (Optional):
                    <input
                      type="text"
                      value={location.address}
                      onChange={(e) => handleLocationChange(index, 'address', e.target.value)}
                      placeholder="Enter the physical address (if applicable)"
                    />
                  </label>
                  {formData.locations.length > 1 && (
                    <button
                      type="button"
                      className="remove-location-button"
                      onClick={() => removeLocation(index)}
                    >
                      Remove Location
                    </button>
                  )}
                </div>
              ))}
              <button type="button" className="add-location-button" onClick={addLocation}>
                + Add Location
              </button>
              <div className="navigation-buttons">
                <button type="button" className="prev-button" onClick={prevStep}>
                  Back
                </button>
                <button type="button" className="next-button" onClick={nextStep}>
                  Next
                </button>
              </div>
            </div>
          );        
        case 4:
          return (
            <div className="form-section">
              <h3>Additional Details</h3>
  
              {formData.orderMethods.map((orderMethod, index) => (
                <div key={index} className="order-method-section">
                  <label>
                    Preferred Order Method:
                    <select
                      value={orderMethod.method}
                      onChange={(e) => handleOrderMethodChange(index, 'method', e.target.value)}
                      required
                    >
                      <option value="">Select an order method</option>
                      <option value="Website">Website</option>
                      <option value="Social Media">Social Media</option>
                      <option value="Mobile">Mobile</option>
                    </select>
                  </label>
                  
                  {orderMethod.method === 'Website' && (
                    <label>
                      Website URL:
                      <input
                        type="url"
                        value={orderMethod.details}
                        onChange={(e) => handleOrderMethodChange(index, 'details', e.target.value)}
                        placeholder="Enter website URL"
                        required
                      />
                    </label>
                  )}
                  
                  {orderMethod.method === 'Social Media' && (
                    <div className="social-media-input">
                      <label>
                        Social Media Platform:
                        <select
                          value={orderMethod.type || ''}
                          onChange={(e) => handleOrderMethodChange(index, 'type', e.target.value)}
                          required
                        >
                          <option value="">Select a platform</option>
                          <option value="Facebook">Facebook</option>
                          <option value="Instagram">Instagram</option>
                          <option value="Snapchat">Snapchat</option>
                          <option value="Whatsapp">Whatsapp</option>
                          <option value="Other">Other</option>
                        </select>
                      </label>
                      {orderMethod.type && (
                        <label>
                          Username:
                          <input
                            type="text"
                            value={orderMethod.username}
                            onChange={(e) => handleOrderMethodChange(index, 'username', e.target.value)}
                            placeholder={`Enter your username for ${orderMethod.type}`}
                            required
                          />
                        </label>
                      )}
                    </div>
                  )}
                  
                  {orderMethod.method === 'Mobile' && (
                    <label>
                      Mobile Number:
                      <input
                        type="tel"
                        value={orderMethod.details}
                        onChange={(e) => handleOrderMethodChange(index, 'details', e.target.value)}
                        placeholder="Enter mobile number"
                        required
                      />
                    </label>
                  )}
  
                  {/* Remove Button */}
                  <button type="button" className="remove-order-method" onClick={() => removeOrderMethod(index)}>
                    Remove
                  </button>
                </div>
              ))}
  
              <button type="button" onClick={addOrderMethod} className="add-order-method-button">
                + Add Order/Booking Method
              </button>
  
              {errors.orderMethods && <span className="error-message">{errors.orderMethods}</span>}
  
              <div className="navigation-buttons">
                <button type="button" className="prev-button" onClick={prevStep}>Back</button>
                <button type="button" className="next-button" onClick={nextStep}>Next</button>
            </div>
            </div>
          );
          case 5:
        return (
          <div className="form-section">
            <h3>Business Images</h3>
            <label>
              Logo:
              <input type="file" name="logo" onChange={handleFileChange} />
            </label>
            <label>
              Banner:
              <input type="file" name="banner" onChange={handleFileChange} />
            </label>
            <label>
              Additional Images:
              <input type="file" name="additionalImages" multiple onChange={handleAdditionalImagesChange} />
            </label>
            <div className="navigation-buttons">
              <button type="button" className="prev-button" onClick={prevStep}>Back</button>
              <button type="button" className="next-button" onClick={nextStep}>Next</button>
            </div>
          </div>
        );
      case 6:
        return (
          <div className="form-section">
            <h3>Services/Menu Items and Pricing</h3>
            {formData.servicesAndPrices.map((service, index) => (
              <div key={index} className="service-price-section">
                <label>
                  Service Name:
                  <input
                    type="text"
                    value={service.name}
                    onChange={(e) => handleServiceChange(index, 'name', e.target.value)}
                    placeholder="Enter service name"
                  />
                </label>
                <label>
                  Price:
                  <input
                    type="number"
                    value={service.price}
                    onChange={(e) => handleServiceChange(index, 'price', e.target.value)}
                    placeholder="Enter price"
                  />
                </label>
                <button type="button" onClick={() => removeService(index)} className="remove-service-button">
                  Remove
                </button>
              </div>
            ))}
            <button type="button" onClick={handleAddService} className="add-service-button">
              + Add Service
            </button>
            <div className="navigation-buttons">
              <button type="button" className="prev-button" onClick={prevStep}>Back</button>
              <button type="submit" className="submit-button">Submit</button>
            </div>
          </div>
        );
      default:
        return null;
    }
  };

  const ProgressBar = ({ step, totalSteps }) => {
    const percentage = (step / totalSteps) * 100;

    return (
      <div className="progress-bar">
        <div className="progress-bar-fill" style={{ width: `${percentage}%` }} />
        <span className="progress-bar-text">Step {step} of {totalSteps}</span>
      </div>
    );
  };

  return (
    <div className="service-provider-form">
      <h1>Become a Service Provider</h1>
      <ProgressBar step={step} totalSteps={totalSteps} />
      <form onSubmit={handleSubmit}>
        {renderStep()}
      </form>
      <button onClick={() => navigate('/')}>Back Home</button>
    </div>
  );
};

export default BecomeServiceProvider;
