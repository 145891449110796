import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import LandingPage from './components/pages/LandingPage';
import SelectContinent from './components/pages/SelectContinent';
import AfricaPage from './components/pages/country_pages/AfricaPage';
import AsiaPage from './components/pages/country_pages/AsiaPage';
import EuropePage from './components/pages/country_pages/EuropePage';
import NorthAmericaPage from './components/pages/country_pages/NorthAmericaPage';
import SouthAmericaPage from './components/pages/country_pages/SouthAmericaPage';
import AustraliaPage from './components/pages/country_pages/AustraliaPage';
import FoodServicesPage from './components/pages/country_pages/FoodServicesPage';
import LocalBusinessesPage from './components/pages/country_pages/LocalBusinessesPage';
import HairServicesPage from './components/pages/country_pages/HairServicesPage';
import OtherServicesPage from './components/pages/country_pages/OtherServicesPage';
import EnterLocationPage from './components/pages/EnterLocationPage';
import { Analytics } from "@vercel/analytics/react"
import Login from './components/pages/Login';
import SignUp from './components/pages/SignUp';
import ServiceDetailPage from './components/pages/ServiceDetailPage';
import CartPage from './components/pages/CartPage';
import BecomeServiceProvider from './components/pages/BecomeServiceProvider';
import ServiceSubmit from './components/pages/ServiceSubmit';
import UserProfile from './components/pages/UserProfile';
import SubmissionSuccess from './components/pages/SubmissionSuccess';
import ScrollToTop from './components/ScrollToTop';
import OrderPage from './components/pages/OrderPage';
import ProviderDashboard from './components/pages/ProviderDashboard';
import ServiceAnalytics from './components/pages/ServiceAnalytics';
import PricingPage from './components/pages/PricingPage';
import AdminDashboard from './components/pages/AdminDashboard';
import { useEffect, useState } from 'react';
import { getAuth, onAuthStateChanged } from "firebase/auth";
import { doc, getDoc } from 'firebase/firestore';
import { db } from './firebase';


function App() {
  const [user, setUser] = useState(null);
  const [isAdmin, setIsAdmin] = useState(false);
  const auth = getAuth();

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, async (currentUser) => {
      setUser(currentUser); // Store user info

      if (currentUser) {
        //Check Firestore `admins` collection
        const adminRef = doc(db, 'admins', currentUser.uid);
        const adminSnap = await getDoc(adminRef);

        setIsAdmin(adminSnap.exists()); // True if user is an admin
      } else {
        setIsAdmin(false); // No user = No admin access
      }
    });

    return () => unsubscribe(); // Cleanup listener
  }, [auth]);


  return (
    <Router>
      <ScrollToTop />
    <div className="App">
      <Routes>
        <Route path="/" element={<LandingPage />} />
        <Route path="/login" element={<Login />} />
        <Route path="/signup" element={<SignUp />} />
        <Route path="/enter-location" element={<EnterLocationPage />} />
        <Route path="/select-continent" element={<SelectContinent />} />
        <Route path="/continent/africa" element={<AfricaPage />} />
        <Route path="/continent/asia" element={<AsiaPage />} />
        <Route path="/continent/europe" element={<EuropePage />} />
        <Route path="/continent/north-america" element={<NorthAmericaPage />} />
        <Route path="/continent/south-america" element={<SouthAmericaPage />} />
        <Route path="/continent/australia" element={<AustraliaPage />} />
        <Route path="/food-services/:continent" element={<FoodServicesPage />} />
        <Route path="/local-businesses/:continent" element={<LocalBusinessesPage />} />
        <Route path="/hair-services/:continent" element={<HairServicesPage />} />
        <Route path="/other-services/:continent" element={<OtherServicesPage />} />
        <Route path="/service/:serviceId" element={<ServiceDetailPage />} />
        <Route path="/order" element={<OrderPage />} />
        <Route path="/cart" element={<CartPage />} />
        <Route path='/become-service-provider' element={<BecomeServiceProvider />} />
        <Route path='/service-submit' element={<ServiceSubmit />} />
        <Route path="/user-profile" element={<UserProfile />} />
        <Route path="/submission-success" element={<SubmissionSuccess />} />
        <Route path="/provider-dashboard/:providerId" element={<ProviderDashboard />} />
        <Route path="/provider-dashboard/:providerId/service-analytics/:serviceId" element={<ServiceAnalytics />} />
        <Route path='/pricing' element={<PricingPage />} />

        {/* Protect Admin Dashboard */}
        <Route
          path="/admin-dashboard"
          element={isAdmin ? <AdminDashboard /> : <LandingPage/>}
        />
      </Routes>
      <Analytics />

      <footer className="footer">
        <p>© 2024 Servillia. All Rights Reserved.</p>
        <div className="footer-links">
          <a href="/become-service-provider">Become a Service Provider</a>
          <a href="/pricing">Pricing</a>
          <a href="/terms">Terms of Service</a>
          <a href="/privacy">Privacy Policy</a>
          <a href="/contact">Contact Us</a>
        </div>
      </footer>
    </div>
    </Router>
  );
}

export default App;
