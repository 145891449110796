import React, { useState, useEffect } from 'react';
import { useParams, useLocation } from 'react-router-dom';
import ServicePage from '../ServicePage';
import hair from '../../../assets/africa/hair.jpg'
import afv from '../../../assets/africa/afv.png';
import bura from '../../../assets/africa/bura.jpg';
import mySpiceHouse from '../../../assets/africa/my-spice-house.png';
import northGarden from '../../../assets/asia/north-garden.png';
import punjab from '../../../assets/asia/punjab.png';
import kimchi from '../../../assets/asia/kimchi.jpg';
import hairImg from '../../../assets/hair.png'
import royal from '../../../assets/africa/royal-touch.jpg'
// Add imports for Asia or other continents food images here

import {db} from '../../../firebase'
import { collection, getDocs, query, where } from 'firebase/firestore';

const hairServicesData = {
  africa: [
    {
      image: hair,
      title: "African Food",
      description: "Delicious African cuisine to satisfy your cravings.",
      buttonText: "Book Now"
    },
    {
        image: bura,
        title: "Bura International.",
        description: "African Hair Store.",
        buttonText: "Book Now"
    },
    {
      image: royal,
      title: "Royal Touch Hair Salon",
      description: "Hair salon on Pembina Highway.",
      buttonText: "Book Now"
    },
    {
      image: hairImg,
      title: "Hair Service 2",
      description: "Quick description or rating of Hair service 2.",
      buttonText: "Book Now"
    },
    {
      image: hairImg,
      title: "Hair Service 3",
      description: "Quick description or rating of Hair service 3.",
      buttonText: "Book Now"
    },
    {
      image: hairImg,
      title: "Hair Service 4",
      description: "Quick description or rating of Hair service 4.",
      buttonText: "Book Now"
    },
    {
      image: hairImg,
      title: "Hair Service 5",
      description: "Quick description or rating of Hair service 5.",
      buttonText: "Book Now"
    },
    {
      image: hairImg,
      title: "Hair Service 6",
      description: "Quick description or rating of Hair service 6.",
      buttonText: "Book Now"
    },
    {
      image: hairImg,
      title: "Hair Service 7",
      description: "Quick description or rating of Hair service 7.",
      buttonText: "Book Now"
    },
    {
      image: hairImg,
      title: "Hair Service 8",
      description: "Quick description or rating of Hair service 8.",
      buttonText: "Book Now"
    },
    {
      image: hairImg,
      title: "Hair Service 9",
      description: "Quick description or rating of Hair service 9.",
      buttonText: "Book Now"
    },
    {
      image: hairImg,
      title: "Hair Service 10",
      description: "Quick description or rating of Hair service 10.",
      buttonText: "Book Now"
    },
    {
      image: hairImg,
      title: "Hair Service 11",
      description: "Quick description or rating of Hair service 11.",
      buttonText: "Book Now"
    },
    {
      image: hairImg,
      title: "Hair Service 12",
      description: "Quick description or rating of Hair service 12.",
      buttonText: "Book Now"
    },
    // Add more African food services here
  ],
  asia: [
    {
        image: kimchi,
        title: "Kimchi",
        description: "Taste the flavors of Asia.",
        buttonText: "Book Now"
    },
    {
      image: hairImg,
      title: "Hair Service 1",
      description: "Quick description or rating of Hair service 1.",
      buttonText: "Book Now"
    },
    {
      image: hairImg,
      title: "Hair Service 2",
      description: "Quick description or rating of Hair service 2.",
      buttonText: "Book Now"
    },
    {
      image: hairImg,
      title: "Hair Service 3",
      description: "Quick description or rating of Hair service 3.",
      buttonText: "Book Now"
    },
    {
      image: hairImg,
      title: "Hair Service 4",
      description: "Quick description or rating of Hair service 4.",
      buttonText: "Book Now"
    },
    {
      image: hairImg,
      title: "Hair Service 5",
      description: "Quick description or rating of Hair service 5.",
      buttonText: "Book Now"
    },
    {
      image: hairImg,
      title: "Hair Service 6",
      description: "Quick description or rating of Hair service 6.",
      buttonText: "Book Now"
    },
    {
      image: hairImg,
      title: "Hair Service 7",
      description: "Quick description or rating of Hair service 7.",
      buttonText: "Book Now"
    },
    {
      image: hairImg,
      title: "Hair Service 8",
      description: "Quick description or rating of Hair service 8.",
      buttonText: "Book Now"
    },
    {
      image: hairImg,
      title: "Hair Service 9",
      description: "Quick description or rating of Hair service 9.",
      buttonText: "Book Now"
    },
    {
      image: hairImg,
      title: "Hair Service 10",
      description: "Quick description or rating of Hair service 10.",
      buttonText: "Book Now"
    },
    {
      image: hairImg,
      title: "Hair Service 11",
      description: "Quick description or rating of Hair service 11.",
      buttonText: "Book Now"
    },
    {
      image: hairImg,
      title: "Hair Service 12",
      description: "Quick description or rating of Hair service 12.",
      buttonText: "Book Now"
    },
  ],
  // Add more continents and their respective food services here
};

const HairServicesPage = () => {
  const { continent } = useParams();
  const location = useLocation();
  const { name, city } = location.state || {};
  const [loading, setLoading] = useState(true);


  const [hairServices, setHairServices] = useState([]); // State for fetched services

  // // Get the food services for the specific continent
  // const hairServices = hairServicesData[continent.toLowerCase()] || [];


  useEffect(() => {
    const fetchServices = async () => {
      try {
        const servicesArray = [];
  
        // First query: Check the 'city' field directly and approved status
        const cityQuery = query(
          collection(db, 'services'),
          where('continent', '==', continent.toLowerCase()),
          where('category', '==', 'hair'),
          where('city', '==', city.toLowerCase()),
          where('approved', '==', true) // Add approved filter
        );
        const cityQuerySnapshot = await getDocs(cityQuery);
        cityQuerySnapshot.forEach((doc) => {
          servicesArray.push({ id: doc.id, ...doc.data() });
        });
  
        // Second query: Check if the 'locations' array contains a matching city and approved status
        const allServicesSnapshot = await getDocs(
          query(
            collection(db, 'services'),
            where('continent', '==', continent.toLowerCase()),
            where('category', '==', 'hair'),
            where('approved', '==', true) // Add approved filter
          )
        );
        allServicesSnapshot.forEach((doc) => {
          const data = doc.data();
          if (
            data.locations &&
            data.locations.some((loc) => loc.city.toLowerCase() === city.toLowerCase())
          ) {
            servicesArray.push({ id: doc.id, ...data });
          }
        });
  
        // Merge and remove duplicates
        const uniqueServices = Array.from(
          new Map(servicesArray.map((service) => [service.id, service])).values()
        );
  
        setHairServices(uniqueServices);
      } catch (error) {
        console.error('Error fetching services:', error);
      } finally {
        setTimeout(() => {
          setLoading(false); // Set loading to false after data is fetched
        }, 100); // timeout duration
      }
    };
  
    fetchServices();
  }, [continent, city]);

  if (loading) {
    return (
      <div className="loading-container">
        <p>Loading services...</p>
      </div>
    );
  }

  return (
    <ServicePage
      pageTitle={`Hair Services`}
      serviceItems={hairServices}
      initialCity={city || "Winnipeg"} // Default to Winnipeg if city is not provided
      userName={name || "Guest"} // Default to Guest if name is not provided
    />
  );
};

export default HairServicesPage;
