import React, { useEffect, useState } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { db } from '../../firebase';
import { doc, getDoc, collection, query, where, getDocs } from 'firebase/firestore';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowLeft } from '@fortawesome/free-solid-svg-icons';

function ServiceAnalytics() {
  const { serviceId } = useParams();
  const [serviceDetails, setServiceDetails] = useState(null);
  const [clickData, setClickData] = useState([]);
  const [loading, setLoading] = useState(true);
  const navigate = useNavigate();

  useEffect(() => {
    const fetchServiceDetails = async () => {
      try {
        const serviceRef = doc(db, 'services', serviceId);
        const serviceSnap = await getDoc(serviceRef);
        if (serviceSnap.exists()) {
          setServiceDetails(serviceSnap.data());
        }
      } catch (error) {
        console.error('Error fetching service details:', error);
      }
    };

    const fetchClickData = async () => {
      try {
        const clicksRef = collection(db, 'service_clicks');
        const q = query(clicksRef, where('serviceId', '==', serviceId));
        const querySnapshot = await getDocs(q);
        const clicks = querySnapshot.docs.map((doc) => doc.data());
        setClickData(clicks);
      } catch (error) {
        console.error('Error fetching click data:', error);
      } finally {
        setLoading(false);
      }
    };

    fetchServiceDetails();
    fetchClickData();
  }, [serviceId]);

  // Process Click Data
  const uniqueUsers = new Set(clickData.map((click) => click.userId || click.userIp)).size;

  // Group clicks by day
  const clicksByDay = clickData.reduce((acc, click) => {
    const date = new Date(click.timestamp?.seconds * 1000).toLocaleDateString();
    acc[date] = (acc[date] || 0) + 1;
    return acc;
  }, {});

  // Process clicks by location
  const clicksByLocation = clickData.reduce((acc, click) => {
    const location = click.city+", "+click.country || "Unknown";
    acc[location] = (acc[location] || 0) + 1;
    return acc;
  }, {});

  return (
    <div className="analytics-container">
      <FontAwesomeIcon icon={faArrowLeft} className="back-icon" onClick={() => navigate(-1)} />
      
      {loading ? (
        <p>Loading service analytics...</p>
      ) : serviceDetails ? (
        <>
          <h1>{serviceDetails.name} - Analytics</h1>
          <h2>Total Views: {serviceDetails.clickCount || 0}</h2>
          <h2>Unique Users Reached: {uniqueUsers}</h2>

          <h3>Clicks Over Time</h3>
          <ul>
            {Object.entries(clicksByDay).map(([date, count]) => (
              <li key={date}>{date}: {count} clicks</li>
            ))}
          </ul>

          <h3>Clicks by Location</h3>
          <ul>
            {Object.entries(clicksByLocation).map(([location, count]) => (
              <li key={location}>{location}: {count} clicks</li>
            ))}
          </ul>
          <div className="upgrade-section">
            <p>Unlock detailed insights & more leads</p>
            <button onClick={() => navigate('/pricing')}>Upgrade Now</button>
          </div>

        </>
      ) : (
        <p>Service not found.</p>
      )}
    </div>
  );
}

export default ServiceAnalytics;
