import React from 'react';
import './StarRating.css';

/**
 * A reusable star rating component in Google style
 * @param {Object} props Component props
 * @param {number} props.rating The rating value (e.g., 4.2)
 * @param {number} props.count Total number of ratings/reviews
 * @param {string} [props.priceRange] Optional price range (e.g., "$10-20")
 * @param {string} [props.category] Optional category (e.g., "Restaurant")
 * @param {string} [props.className] Optional additional CSS class
 * @returns {JSX.Element} The rendered star rating component
 */
const StarRating = ({ rating, count, priceRange, category, className = '' }) => {
  const renderStars = (rating) => {
    const stars = [];
    const fullStars = Math.floor(rating);
    const hasHalfStar = rating % 1 >= 0.5;
    
    // Add full stars
    for (let i = 0; i < fullStars; i++) {
      stars.push(<span key={`full-${i}`} className="star-filled">★</span>);
    }
    
    // Add half star if needed
    if (hasHalfStar) {
      stars.push(<span key="half" className="star-half">★</span>);
    }
    
    // Add empty stars
    const emptyStarsCount = 5 - fullStars - (hasHalfStar ? 1 : 0);
    for (let i = 0; i < emptyStarsCount; i++) {
      stars.push(<span key={`empty-${i}`} className="star-empty">★</span>);
    }
    
    return stars;
  };

  return (
    <div className={`rating-container ${className}`}>
      <div className="rating-score">{rating.toFixed(1)}</div>
      <div className="rating-stars">{renderStars(rating)}</div>
      {count > 0 && <div className="rating-count">({count})</div>}
      {priceRange && <div className="price-range">{priceRange}</div>}
      {category && <div className="service-category">{category}</div>}
    </div>
  );
};

export default StarRating;