import React, { useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { db } from '../../firebase';
import { doc, getDoc, collection, getDocs, updateDoc, addDoc, increment, serverTimestamp } from 'firebase/firestore';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowLeft, faGlobe, faLocationDot, faEdit } from '@fortawesome/free-solid-svg-icons';
import { Carousel } from 'react-responsive-carousel';
import 'react-responsive-carousel/lib/styles/carousel.min.css';
import { getAuth } from 'firebase/auth';
import './ServiceDetailPage.css';
import StarRating from '../StarRating';

const ServiceDetailPage = () => {
  const { serviceId } = useParams();
  const [serviceDetails, setServiceDetails] = useState(null);
  const [items, setItems] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [showRequestForm, setShowRequestForm] = useState(false);
  const [selectedModifications, setSelectedModifications] = useState([]);
  const [additionalDetails, setAdditionalDetails] = useState('');
  const [isProvider, setIsProvider] = useState(false);
  const [showReviewModal, setShowReviewModal] = useState(false);
  const [rating, setRating] = useState(0);
  const [userReview, setUserReview] = useState(false);
  const [reviewText, setReviewText] = useState('');
  const [reviews, setReviews] = useState([]);

  
  const navigate = useNavigate();
  const auth = getAuth();

  const avgRating =
  serviceDetails?.ratingTotal != null &&
  serviceDetails?.ratingCount != null &&
  (serviceDetails.ratingTotal / serviceDetails.ratingCount);

  // Fetch service details and items
  useEffect(() => {
    const fetchServiceDetails = async () => {
      try {
        setLoading(true);
        const docRef = doc(db, 'services', serviceId);
        const docSnap = await getDoc(docRef);

        if (!docSnap.exists()) {
          setError('Service not found');
          setLoading(false);
          return;
        }

        const serviceData = docSnap.data();
        setServiceDetails(serviceData);

        // Check if current user is the provider
        const user = auth.currentUser;
        if (user && serviceData.providerId === user.uid) {
          setIsProvider(true);
        }

        // Determine subcollection name based on category
        let subcollectionName = '';
        if (serviceData.category === 'food') {
          subcollectionName = 'menu';
        } else if (['hair', 'other'].includes(serviceData.category)) {
          subcollectionName = 'services';
        }

        // Fetch items from subcollection if it exists
        if (subcollectionName) {
          try {
            const itemsRef = collection(db, 'services', serviceId, subcollectionName);
            const itemsSnap = await getDocs(itemsRef);
            const itemsList = itemsSnap.docs.map(doc => ({
              id: doc.id,
              ...doc.data()
            }));
            setItems(itemsList);
          } catch (error) {
            console.error('Error fetching items:', error);
          }
        }

        setLoading(false);
      } catch (error) {
        console.error('Error fetching service details:', error);
        setError('Failed to load service details');
        setLoading(false);
      }
    };

    fetchServiceDetails();
  }, [serviceId, auth]);


  useEffect(() => {
    const fetchReviews = async () => {
      try {
        const reviewsRef = collection(db, 'services', serviceId, 'reviews');
        const snapshot = await getDocs(reviewsRef);
        const reviewsList = snapshot.docs.map(doc => ({
          id: doc.id,
          ...doc.data()
        }));
        setReviews(reviewsList);

        const user = auth.currentUser;
        if (user) {
          const existingReview = reviewsList.find(
            (review) => review.userId === user.uid
          );
          if (existingReview) {
            setUserReview(existingReview);
            setRating(existingReview.rating);       // preload existing rating
            setReviewText(existingReview.comment);  // preload existing comment
          }
        }
      } catch (error) {
        console.error("Error fetching reviews:", error);
      }
    };
  
    fetchReviews();
  }, [serviceId]);

  // Handle modification form toggling
  const toggleModification = (option) => {
    setSelectedModifications(prev => 
      prev.includes(option) 
        ? prev.filter(item => item !== option) 
        : [...prev, option]
    );
  };

  const handleSubmitReview = async () => {
    if (!rating) {
      alert("Please select a star rating before submitting.");
      return;
    }
  
    try {
      const user = auth.currentUser;
      if (!user) {
        alert("You must be logged in to submit a review.");
        return;
      }
  
      const reviewData = {
        rating,
        comment: reviewText,
        updatedAt: new Date(),
      };
  
      const reviewRef = collection(db, 'services', serviceId, 'reviews');
  
      if (userReview) {
        // Update the existing review
        const docRef = doc(db, 'services', serviceId, 'reviews', userReview.id);
        await updateDoc(docRef, reviewData);
        alert("Your review has been updated!");
      } else {
        // Add new review
        const newReviewData = {
          ...reviewData,
          userId: user.uid,
          serviceId,
          createdAt: new Date(),
        };
  
        await addDoc(reviewRef, newReviewData);
  
        // Update rating totals
        await updateDoc(doc(db, 'services', serviceId), {
          ratingTotal: increment(rating),
          ratingCount: increment(1),
        });
  
        alert("Thanks for your review!");
      }
  
      setShowReviewModal(false);
      setUserReview({ ...userReview, ...reviewData });
      window.location.reload();
    } catch (err) {
      console.error("Error submitting review:", err);
      alert("There was a problem submitting your review.");
    }
  };
  
  
  


    const handleSubmitRequest = async () => {
      if (selectedModifications.length === 0 && !additionalDetails.trim()) {
        alert('Please select at least one modification or provide details.');
        return;
      }
    
      try {
        const modificationRequest = {
          serviceId,
          serviceName: serviceDetails.name,
          providerId: auth.currentUser?.uid || 'anonymous',
          providerEmail: auth.currentUser?.email || 'No Email',
          changes: selectedModifications,
          details: additionalDetails,
          timestamp: serverTimestamp(),
        };
    
        // Add the request to Firestore collection
        await addDoc(collection(db, 'modification_requests'), modificationRequest);
    
        alert('Your modification request has been submitted.');
        setShowRequestForm(false);
        setSelectedModifications([]);
        setAdditionalDetails('');
      } catch (error) {
        console.error("Error submitting modification request:", error);
        alert("Failed to submit modification request.");
      }
    };


  // Navigate to order page with item details
  const handleOrderClick = (item) => {
    navigate('/order', { 
      state: { 
        serviceDetails, 
        item 
      }
    });
  };

  // Error and loading states
  if (loading) {
    return (
      <div className="service-detail-loading">
        <div className="loading-spinner"></div>
        <p>Loading service details...</p>
      </div>
    );
  }

  if (error) {
    return (
      <div className="service-detail-error">
        <h2>Error</h2>
        <p>{error}</p>
        <button onClick={() => navigate(-1)} className="back-button">
          <FontAwesomeIcon icon={faArrowLeft} /> Go Back
        </button>
      </div>
    );
  }

  if (!serviceDetails) {
    return null;
  }

  // Get business images with fallback
  const businessImages = serviceDetails.businessImages?.length 
    ? serviceDetails.businessImages 
    : Array(5).fill(serviceDetails.banner || serviceDetails.logo);

  return (
    <div className="service-detail-page">
      <div className="service-detail-header">
        <button 
          className="back-button" 
          onClick={() => navigate(-1)}
          aria-label="Go back"
        >
          <FontAwesomeIcon icon={faArrowLeft} />
          <span>Back</span>
        </button>
        
        {isProvider && (
          <button 
            className="edit-button"
            onClick={() => navigate(`/edit-service/${serviceId}`)}
            aria-label="Edit service"
          >
            <FontAwesomeIcon icon={faEdit} />
            <span>Change Layout</span>
          </button>
        )}
      </div>

      <div className="service-banner-container">
        {serviceDetails.banner && (
          <img 
            src={serviceDetails.banner || serviceDetails.logo} 
            alt={serviceDetails.name} 
            className="service-banner"
          />
        )}
      </div>

      <div className="service-detail-content">
        <h1 className="service-name">{serviceDetails.name}</h1>

        {/* Render items based on category */}
        <div className="items-section">
          {serviceDetails.category === 'food' && (
            <div className="menu-section">
              <h2>Menu</h2>
              <div className="items-container">
                {items.length > 0 ? (
                  items.map((item) => (
                    <div 
                      key={item.id} 
                      className="item-card" 
                      onClick={() => handleOrderClick(item)}
                    >
                      {item.logo && (
                        <img 
                          src={item.logo} 
                          alt={item.name} 
                          className="item-image"
                          loading="lazy"
                        />
                      )}
                      <div className="item-details">
                        <h3>{item.name}</h3>
                        <p>{item.description}</p>
                        <span className="item-price">${item.price || '0.00'}</span>
                      </div>
                    </div>
                  ))
                ) : (
                  <p className="no-items-message">No menu items available</p>
                )}
              </div>
            </div>
          )}

          {['hair', 'other'].includes(serviceDetails.category) && (
            <div className="services-section">
              <h2>Services</h2>
              <div className="items-container">
                {items.length > 0 ? (
                  items.map((item) => (
                    <div 
                      key={item.id} 
                      className="item-card" 
                      onClick={() => handleOrderClick(item)}
                    >
                      {item.logo && (
                        <img 
                          src={item.logo} 
                          alt={item.name} 
                          className="item-image"
                          loading="lazy"
                        />
                      )}
                      <div className="item-details">
                        <h3>{item.name}</h3>
                        <p>{item.description || 'No description available'}</p>
                        <span className="item-price">${item.price || '0.00'}</span>
                      </div>
                    </div>
                  ))
                ) : (
                  <p className="no-items-message">No services available</p>
                )}
              </div>
            </div>
          )}

          {serviceDetails.category === 'local' && (
            <div className="local-business-section">
              <h2>Visit {serviceDetails.name}</h2>
              <div className="business-actions">
                {serviceDetails.website && (
                  <a 
                    href={serviceDetails.website} 
                    target="_blank" 
                    rel="noopener noreferrer"
                    className="visit-button"
                  >
                    <FontAwesomeIcon icon={faGlobe} />
                    <span>Visit Website</span>
                  </a>
                )}
                
                {serviceDetails.location && (
                  <a 
                    href={`https://maps.google.com/?q=${encodeURIComponent(serviceDetails.location)}`}
                    target="_blank" 
                    rel="noopener noreferrer"
                    className="location-button"
                  >
                    <FontAwesomeIcon icon={faLocationDot} />
                    <span>View Location</span>
                  </a>
                )}
              </div>
            </div>
          )}
        </div>

        {/* Modification Request Button */}
        {isProvider && (
        <div className="modification-section">
          <button 
            className="request-modification-button"
            onClick={() => setShowRequestForm(true)}
          >
            Request Modification
          </button>

          {showRequestForm && (
            <div className="modification-modal">
              <div className="modal-container">
                <h2>Request Modification</h2>
                <p>Select what you want to modify:</p>
                
                <div className="modification-options">
                  {['Logo', 'Other Images', 'Business Name', 'Description', 'Prices', 'Order Method', 'Other'].map((option) => (
                    <div 
                      key={option} 
                      className={`modification-option ${selectedModifications.includes(option) ? 'selected' : ''}`}
                      onClick={() => toggleModification(option)}
                    >
                      <input
                        type="checkbox"
                        id={`mod-${option}`}
                        checked={selectedModifications.includes(option)}
                        onChange={() => {}}
                      />
                      <label htmlFor={`mod-${option}`}>{option}</label>
                    </div>
                  ))}
                </div>
                
                <textarea
                  placeholder="Provide additional details about your request..."
                  value={additionalDetails}
                  onChange={(e) => setAdditionalDetails(e.target.value)}
                  aria-label="Additional modification details"
                />
                
                <div className="modal-actions">
                  <button 
                    className="submit-modification-button"
                    onClick={handleSubmitRequest}
                  >
                    Submit Request
                  </button>
                  
                  <button 
                    className="cancel-button"
                    onClick={() => setShowRequestForm(false)}
                  >
                    Cancel
                  </button>
                </div>
              </div>
            </div>
          )}
        </div>
        )}

        {/* About Section with Carousel */}
        <div className="about-section">
          <h2>About {serviceDetails.name}</h2>
          <p>{serviceDetails.longDescription || serviceDetails.description}</p>
          
          {businessImages.length > 0 && (
            <div className="service-carousel-container">
              <Carousel
                showThumbs={false}
                infiniteLoop={true}
                autoPlay={true}
                interval={5000}
                stopOnHover={true}
                showArrows={true}
                showStatus={false}
                emulateTouch={true}
              >
                {businessImages.map((image, index) => (
                  <div key={index} className="carousel-slide">
                    <img 
                      src={image} 
                      alt={`${serviceDetails.name} - Image ${index + 1}`} 
                      className="service-carousel-image" 
                    />
                    {serviceDetails.category === 'food' && (
                      <button
                        className="service-carousel-button"
                        onClick={() => navigate('/order', { state: { serviceDetails } })}
                      >
                        Order Now
                      </button>
                    )}
                  </div>
                ))}
              </Carousel>
            </div>
          )}
          <button 
            className="rate-service-button" 
            onClick={() => {
              if (!auth.currentUser) {
                alert("You must be logged in to rate this service.");
              } else {
                setShowReviewModal(true);
              }
            }}
          >
            ⭐ {userReview ? "Edit Your Review" : "Rate This Service"}
          </button>

          {reviews.length > 0 && (
            <>
            <StarRating 
              rating={avgRating}
              count={serviceDetails.ratingCount}
              priceRange={serviceDetails.priceRange}
            />
            <div className="reviews-section">
              <h3>Customer Reviews</h3>
              {reviews
                .sort((a, b) => b.createdAt?.toDate() - a.createdAt?.toDate()) // Newest first
                .map((review) => (
                  <div key={review.id} className="review-item">
                    <div className="review-rating">
                      {"★".repeat(review.rating)}{"☆".repeat(5 - review.rating)}
                    </div>
                    {review.comment && <p className="review-text">"{review.comment}"</p>}
                  </div>
              ))}
            </div>
            </>
          )}


          {showReviewModal && (
            <div className="review-modal-overlay">
              <div className="review-modal">
                <h3>Rate This Service</h3>

                <div className="star-rating">
                  {[1, 2, 3, 4, 5].map((value) => (
                    <span
                      key={value}
                      className={`star ${value <= rating ? "filled" : ""}`}
                      onClick={() => setRating(value)}
                    >
                      ★
                    </span>
                  ))}
                </div>

                <textarea
                  placeholder="Optional: Write a short review..."
                  value={reviewText}
                  onChange={(e) => setReviewText(e.target.value)}
                />

                <div className="review-actions">
                  <button onClick={handleSubmitReview}>Submit</button>
                  <button onClick={() => setShowReviewModal(false)}>Cancel</button>
                </div>
              </div>
            </div>
          )}

        </div>
      </div>
    </div>
  );
};

export default ServiceDetailPage;