import React, { useState, useEffect } from 'react';
import { useParams, useLocation } from 'react-router-dom';
import ServicePage from '../ServicePage';
// import foodImage from '../../../assets/africa/food.jpg';
// import afv from '../../../assets/africa/afv.png';
// import auntyShawarma from '../../../assets/africa/aunty-shawarma.png';
// import northGarden from '../../../assets/asia/north-garden.png';
// import punjab from '../../../assets/asia/punjab.png';
// import kimchi from '../../../assets/asia/kimchi.jpg';
// import food from '../../../assets/food.png'
// Add imports for Asia or other continents food images here


import {db} from '../../../firebase'
import { collection, getDocs, query, where } from 'firebase/firestore';

// const foodServicesData = {
//   africa: [
//     {
//       image: foodImage,
//       title: "African Food",
//       description: "Delicious African cuisine to satisfy your cravings.",
//       buttonText: "Order Now"
//     },
//     {
//       image: afv,
//       title: "AFV",
//       description: "African Restaurant.",
//       buttonText: "Order Now"
//     },
//     {
//       image: auntyShawarma,
//       title: "Aunty Shawarma",
//       description: "Shawarma vendor in Winnipeg.",
//       buttonText: "Order Now"
//     },
//     {
//       image: food,
//       title: "Food Service 1",
//       description: "Quick description or rating of food service 1.",
//       buttonText: "Order Now"
//     },
//     {
//       image: food,
//       title: "Food Service 2",
//       description: "Quick description or rating of food service 2.",
//       buttonText: "Order Now"
//     },
//     {
//       image: food,
//       title: "Food Service 3",
//       description: "Quick description or rating of food service 3.",
//       buttonText: "Order Now"
//     },
//     {
//       image: food,
//       title: "Food Service 4",
//       description: "Quick description or rating of food service 4.",
//       buttonText: "Order Now"
//     },
//     {
//       image: food,
//       title: "Food Service 5",
//       description: "Quick description or rating of food service 5.",
//       buttonText: "Order Now"
//     },
//     {
//       image: food,
//       title: "Food Service 6",
//       description: "Quick description or rating of food service 6.",
//       buttonText: "Order Now"
//     },
//     {
//       image: food,
//       title: "Food Service 7",
//       description: "Quick description or rating of food service 7.",
//       buttonText: "Order Now"
//     },
//     {
//       image: food,
//       title: "Food Service 8",
//       description: "Quick description or rating of food service 8.",
//       buttonText: "Order Now"
//     },
//     {
//       image: food,
//       title: "Food Service 9",
//       description: "Quick description or rating of food service 9.",
//       buttonText: "Order Now"
//     },
//     {
//       image: food,
//       title: "Food Service 10",
//       description: "Quick description or rating of food service 10.",
//       buttonText: "Order Now"
//     },
//     {
//       image: food,
//       title: "Food Service 11",
//       description: "Quick description or rating of food service 11.",
//       buttonText: "Order Now"
//     },
//     {
//       image: food,
//       title: "Food Service 12",
//       description: "Quick description or rating of food service 12.",
//       buttonText: "Order Now"
//     },
//     // Add more African food services here
//   ],
//   asia: [
//     {
//       image: northGarden,
//       title: "North Garden",
//       description: "Taste the flavors of Asia.",
//       buttonText: "Order Now"
//     },
//     {
//         image: punjab,
//         title: "Indian Restaurant",
//         description: "Taste the flavors of Asia.",
//         buttonText: "Order Now"
//     },
//     {
//         image: kimchi,
//         title: "Kimchi",
//         description: "Taste the flavors of Asia.",
//         buttonText: "Order Now"
//     },
//     {
//       image: food,
//       title: "Food Service 1",
//       description: "Quick description or rating of food service 1.",
//       buttonText: "Order Now"
//     },
//     {
//       image: food,
//       title: "Food Service 2",
//       description: "Quick description or rating of food service 2.",
//       buttonText: "Order Now"
//     },
//     {
//       image: food,
//       title: "Food Service 3",
//       description: "Quick description or rating of food service 3.",
//       buttonText: "Order Now"
//     },
//     {
//       image: food,
//       title: "Food Service 4",
//       description: "Quick description or rating of food service 4.",
//       buttonText: "Order Now"
//     },
//     {
//       image: food,
//       title: "Food Service 5",
//       description: "Quick description or rating of food service 5.",
//       buttonText: "Order Now"
//     },
//     {
//       image: food,
//       title: "Food Service 6",
//       description: "Quick description or rating of food service 6.",
//       buttonText: "Order Now"
//     },
//     {
//       image: food,
//       title: "Food Service 7",
//       description: "Quick description or rating of food service 7.",
//       buttonText: "Order Now"
//     },
//     {
//       image: food,
//       title: "Food Service 8",
//       description: "Quick description or rating of food service 8.",
//       buttonText: "Order Now"
//     },
//     {
//       image: food,
//       title: "Food Service 9",
//       description: "Quick description or rating of food service 9.",
//       buttonText: "Order Now"
//     },
//     {
//       image: food,
//       title: "Food Service 10",
//       description: "Quick description or rating of food service 10.",
//       buttonText: "Order Now"
//     },
//     {
//       image: food,
//       title: "Food Service 11",
//       description: "Quick description or rating of food service 11.",
//       buttonText: "Order Now"
//     },
//     {
//       image: food,
//       title: "Food Service 12",
//       description: "Quick description or rating of food service 12.",
//       buttonText: "Order Now"
//     },
//   ],
//   // Add more continents and their respective food services here
// };

const FoodServicesPage = () => {
  const { continent } = useParams();
  const location = useLocation();
  const { name, city } = location.state || {};
  const [loading, setLoading] = useState(true);


  const [foodServices, setFoodServices] = useState([]); // State for fetched services

  

  // Get the food services for the specific continent
  // const foodServices = foodServicesData[continent.toLowerCase()] || [];

  useEffect(() => {
    const fetchServices = async () => {
      try {
        const servicesArray = [];
  
        // First query: Check the 'city' field directly and approved status
        const cityQuery = query(
          collection(db, 'services'),
          where('continent', '==', continent.toLowerCase()),
          where('category', '==', 'food'),
          where('city', '==', city.toLowerCase()),
          where('approved', '==', true) // Add approved filter
        );
        const cityQuerySnapshot = await getDocs(cityQuery);
        cityQuerySnapshot.forEach((doc) => {
          servicesArray.push({ id: doc.id, ...doc.data() });
        });
  
        // Second query: Check if the 'locations' array contains a matching city and approved status
        const allServicesSnapshot = await getDocs(
          query(
            collection(db, 'services'),
            where('continent', '==', continent.toLowerCase()),
            where('category', '==', 'food'),
            where('approved', '==', true) // Add approved filter
          )
        );
        allServicesSnapshot.forEach((doc) => {
          const data = doc.data();
          if (
            data.locations &&
            data.locations.some((loc) => loc.city.toLowerCase() === city.toLowerCase())
          ) {
            servicesArray.push({ id: doc.id, ...data });
          }
        });
  
        // Merge and remove duplicates
        const uniqueServices = Array.from(
          new Map(servicesArray.map((service) => [service.id, service])).values()
        );
  
        setFoodServices(uniqueServices);
      } catch (error) {
        console.error('Error fetching services:', error);
      } finally {
        setTimeout(() => {
          setLoading(false); // Set loading to false after data is fetched
        }, 100); // timeout duration
      }
    };
  
    fetchServices();
  }, [continent, city]);

  if (loading) {
    return (
      <div className="loading-container">
        <p>Loading services...</p>
      </div>
    );
  }

  return (
    <ServicePage
      pageTitle={`Food Services`}
      serviceItems={foodServices}
      initialCity={city || "Winnipeg"} // Default to Winnipeg if city is not provided
      userName={name || "Guest"} // Default to Guest if name is not provided
      continent = {continent}
    />
  );
};

export default FoodServicesPage;
