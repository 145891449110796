import React, { useEffect, useState } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { db } from '../../firebase';
import { doc, getDoc, updateDoc } from 'firebase/firestore';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowLeft, faChartLine, faEye, faPercent } from '@fortawesome/free-solid-svg-icons';
import './ProviderDashboard.css';

function ProviderDashboard() {
  const { providerId } = useParams();
  const [providerDetails, setProviderDetails] = useState(null);
  const [services, setServices] = useState([]);
  const [loading, setLoading] = useState(true);
  const [hasNoPlan, setHasNoPlan] = useState(false); // Track plan status
  const [updatingService, setUpdatingService] = useState(null); // Track which service is being updated
  const navigate = useNavigate();

  useEffect(() => {
    const fetchProviderDetails = async () => {
      try {
        const docRef = doc(db, 'providers', providerId);
        const docSnap = await getDoc(docRef);

        if (docSnap.exists()) {
          const providerData = docSnap.data();
          setProviderDetails(providerData);

          // Check if provider has plan set to "none"
          setHasNoPlan(providerData.plan === "none");

          // Fetch services based on serviceIds
          if (providerData.serviceIds && providerData.serviceIds.length > 0) {
            const servicesList = [];
            for (const serviceId of providerData.serviceIds) {
              const serviceRef = doc(db, 'services', serviceId);
              const serviceSnap = await getDoc(serviceRef);
              if (serviceSnap.exists()) {
                servicesList.push({ 
                  id: serviceId, 
                  ...serviceSnap.data(),
                  // Set default value for hasDiscount if it doesn't exist
                  hasServilliaDiscount: serviceSnap.data().hasServilliaDiscount !== undefined 
                    ? serviceSnap.data().hasServilliaDiscount 
                    : false
                });
              }
            }
            setServices(servicesList);
          }
        }
      } catch (error) {
        console.error('Error fetching provider details:', error);
      } finally {
        setLoading(false);
      }
    };

    fetchProviderDetails();
  }, [providerId]);

  const handleServiceClick = (serviceId) => {
    navigate(`/service/${serviceId}`, { state: { previewMode: true } });
  };

  const handleAnalyticsClick = (serviceId) => {
    navigate(`/provider-dashboard/${providerId}/service-analytics/${serviceId}`);
  };

  const handleDiscountToggle = async (serviceId, currentValue) => {
    try {
      setUpdatingService(serviceId);
      
      // Update in Firestore
      const serviceRef = doc(db, 'services', serviceId);
      await updateDoc(serviceRef, {
        hasServilliaDiscount: !currentValue,
        lastUpdatedDiscount: new Date()
      });
      
      // Update local state
      setServices(prevServices => 
        prevServices.map(service => 
          service.id === serviceId 
            ? { ...service, hasServilliaDiscount: !currentValue } 
            : service
        )
      );
    } catch (error) {
      console.error("Error updating discount setting:", error);
      alert("Failed to update discount setting. Please try again.");
    } finally {
      setUpdatingService(null);
    }
  };

  return (
    <div className="provider-dashboard">
      <div className="dashboard-header">
        <div className="header-container">
          <div className="header-left">
            <FontAwesomeIcon
              icon={faArrowLeft}
              className="back-button-icon"
              onClick={() => navigate(-1)}
            />
            <h1 className="dashboard-title">Provider Dashboard</h1>
          </div>

          {/* Show "Activate Subscription" button only if provider has plan set to "none" */}
          {hasNoPlan && (
            <button className="upgrade-button" onClick={() => navigate('/pricing')}>
              Activate Subscription
            </button>
          )}
        </div>
      </div>

      <div className="main-container">
        {loading ? (
          <div className="loading-container">
            <p>Loading provider details...</p>
          </div>
        ) : (
          <>
            {providerDetails ? (
              <div>
                <div className="section-header">
                  <h2 className="section-title">Your Services</h2>
                  {services.length > 0 && (
                    <p className="service-count">{services.length} active service{services.length !== 1 ? 's' : ''}</p>
                  )}
                </div>

                {services.length > 0 ? (
                  <div className="services-grid">
                    {services.map((service) => (
                      <div key={service.id} className="service-card">
                        <div className="service-image-container">
                          <img
                            src={service.logo || 'https://via.placeholder.com/300x200'}
                            alt={service.name}
                            className="service-image"
                          />
                        </div>
                        <div className="service-details">
                          <h3 className="service-name">{service.name}</h3>
                          <div className="service-stats">
                            <div className="stat">
                              <FontAwesomeIcon icon={faEye} className="stat-icon" />
                              <span>{service.clickCount || 0} views</span>
                            </div>
                          </div>
                          
                          {/* Discount Toggle Section */}
                          <div className="discount-toggle-container">
                            <div className="discount-toggle-label">
                              <FontAwesomeIcon icon={faPercent} className="discount-icon" />
                              <span>Servillia Discount</span>
                            </div>
                            <label className="toggle-switch">
                              <input 
                                type="checkbox"
                                checked={service.hasServilliaDiscount || false}
                                onChange={() => handleDiscountToggle(service.id, service.hasServilliaDiscount)}
                                disabled={updatingService === service.id}
                              />
                              <span className="toggle-slider"></span>
                            </label>
                          </div>
                          
                          <div className="provider-service-actions">
                            <button 
                              className="view-button"
                              onClick={() => handleServiceClick(service.id)}
                            >
                              <FontAwesomeIcon icon={faEye} /> View Page
                            </button>
                            <button 
                              className="analytics-button"
                              onClick={() => handleAnalyticsClick(service.id)}
                            >
                              <FontAwesomeIcon icon={faChartLine} /> Analytics
                            </button>
                          </div>
                        </div>
                      </div>
                    ))}
                  </div>
                ) : (
                  <div className="empty-state">
                    <p>You have no services yet.</p>
                    <button 
                      className="add-service-button"
                      onClick={() => navigate('/add-service')}
                    >
                      Add Your First Service
                    </button>
                  </div>
                )}
              </div>
            ) : (
              <div className="empty-state">
                <p>No provider details found.</p>
                <button 
                  className="back-to-home-button"
                  onClick={() => navigate('/')}
                >
                  Back to Home
                </button>
              </div>
            )}
          </>
        )}
      </div>
    </div>
  );
}

export default ProviderDashboard;