import React, { useState, useEffect } from 'react';
import Header from '../Header';
import AddressModal from '../AddressModal';
import StarRating from '../StarRating'; // Import our new component
import './ServicePage.css';
import NoServices from './NoServices';
import { useNavigate } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBookmark as filledBookmark } from '@fortawesome/free-solid-svg-icons';
import { faBookmark as emptyBookmark } from '@fortawesome/free-regular-svg-icons';
import { getAuth } from 'firebase/auth';
import { db } from '../../firebase'; 
import { addDoc, collection, serverTimestamp } from "firebase/firestore";
import { doc, updateDoc, arrayUnion, arrayRemove, getDoc, setDoc, increment } from 'firebase/firestore';
import sortServicesByPoints from '../../utils/servicePointsAlgorithm';

const ServicePage = ({
  pageTitle,
  serviceItems,
  initialCity,
  userName,
  continent,
}) => {
  const [userAddress, setUserAddress] = useState(initialCity);
  const [showModal, setShowModal] = useState(false);
  const [searchQuery, setSearchQuery] = useState('');
  const [activeCategory, setActiveCategory] = useState('All');
  const [isDarkMode, setIsDarkMode] = useState(false);
  const [favorites, setFavorites] = useState([]);
  const auth = getAuth();
  const navigate = useNavigate();

  // Function to fetch the user's favorite services from Firestore
  const fetchFavorites = async (userId) => {
    const userRef = doc(db, 'users', userId);
    const docSnap = await getDoc(userRef);
  
    if (docSnap.exists()) {
      setFavorites(docSnap.data().favorites || []);
    } else {
      await setDoc(userRef, { favorites: [] });
      setFavorites([]);
    }
  };

  // UseEffect to fetch the user's favorites from Firestore once the user is authenticated
  useEffect(() => {
    const user = auth.currentUser;
    if (user) {
      fetchFavorites(user.uid);
    }
  }, [auth.currentUser]);

  const handleSearchChange = (event) => {
    setSearchQuery(event.target.value);
  };

  const handleSearchSubmit = (event) => {
    event.preventDefault();
    console.log('Search query:', searchQuery);
  };

  const handleCategoryChange = (category) => {
    setActiveCategory(category);
  };

  // Function to determine button text based on service category
  const getButtonText = (category) => {
    switch (category.toLowerCase()) {
      case 'food':
        return 'Order Now';
      case 'hair':
        return 'Book Now';
      case 'other':
        return 'Request Service';
      case 'local':
        return 'Visit';
      default:
        return 'Learn More';
    }
  };

  const navigateToServiceDetail = (serviceId) => {
    navigate(`/service/${serviceId}`, { state: { name: userName } });
  };

  const getUserIPandLocation = async () => {
    try {
      const response = await fetch("https://ipapi.co/json/");
      const data = await response.json();
      return {
        ipAddress: data.ip,
        city: data.city,
        country: data.country_name,
        region: data.region,
      };
    } catch (error) {
      console.error("Error fetching user IP/location:", error);
      return {
        ipAddress: "unknown",
        city: "unknown",
        country: "unknown",
        region: "unknown",
      };
    }
  };

  const handleServiceClick = async (serviceId) => {
    try {
      const user = auth.currentUser;
      const isLoggedIn = !!user;
      const userId = user ? user.uid : "anonymous";
      const userAgent = navigator.userAgent;
  
      // Fetch IP and location
      const { ipAddress, city, country, region } = await getUserIPandLocation();
  
      // Increment clickCount in Firestore
      const serviceDocRef = doc(db, "services", serviceId);
      await updateDoc(serviceDocRef, {
        clickCount: increment(1),
      });
  
      // Store the click in Firestore for analytics
      await addDoc(collection(db, "service_clicks"), {
        serviceId,
        userId,
        isLoggedIn,
        ipAddress,
        city,
        country,
        region,
        userAgent,
        timestamp: serverTimestamp(), 
      });
  
      // Navigate to the service page
      navigate(`/service/${serviceId}`);
    } catch (error) {
      console.error("Error logging service click:", error);
    }
  };

  const toggleDarkMode = () => {
    setIsDarkMode(!isDarkMode);
    document.body.classList.toggle('dark-mode', !isDarkMode);
  };

  const handleFavoriteClick = async (service) => {
    const user = auth.currentUser;
    if (!user) {
      alert('You must be logged in to add to favorites.');
      return;
    }
  
    const userRef = doc(db, 'users', user.uid);
  
    try {
      if (favorites.includes(service.id)) {
        await updateDoc(userRef, {
          favorites: arrayRemove(service.id),
        });
        setFavorites(favorites.filter((favId) => favId !== service.id));
        alert(`${service.name} removed from favorites.`);
      } else {
        await updateDoc(userRef, {
          favorites: arrayUnion(service.id),
        });
        setFavorites([...favorites, service.id]);
        alert(`${service.name} added to favorites.`);
      }
    } catch (error) {
      console.error("Error updating favorites: ", error);
      alert('An error occurred while updating your favorites.');
    }
  };

  const isFavorite = (serviceId) => favorites.includes(serviceId);

  const sortedServices = sortServicesByPoints(serviceItems);


  return (
    <div className="service-page">
      <Header
        searchQuery={searchQuery}
        setSearchQuery={setSearchQuery}
        handleSearchSubmit={handleSearchSubmit}
        handleSearchChange={handleSearchChange}
        handleCategoryChange={handleCategoryChange}
        activeCategory={activeCategory}
        userAddress={userAddress}
        setShowModal={setShowModal}
        toggleDarkMode={toggleDarkMode}
        isDarkMode={isDarkMode}
        logoClickHandler={() => console.log('Logo clicked!')}
        name={userName}
        city={userAddress}
      />

    {sortedServices.length > 0 ? (
        <main>
          <h1 className="service-page-title">{pageTitle} in {userAddress}</h1>
          <div className="services-list">
            {sortedServices.map((service, index) => {
              // Calculate rating if there are reviews
              const averageRating = service.ratingCount > 0 
                ? service.ratingTotal / service.ratingCount 
                : 0;
              
              return (
                <div key={index} className="service-item" >
                  {service.hasServilliaDiscount && (
                    <div className="discount-badge">Discount</div>
                  )}
                  <div className="service-actions">
                    <FontAwesomeIcon
                      icon={isFavorite(service.id) ? filledBookmark : emptyBookmark}
                      className="favorite-icon"
                      onClick={() => handleFavoriteClick(service)}
                    />
                  </div>
                  <img src={service.logo} alt={service.name} />
                  <h3>{service.name}</h3>
                  <p>{service.description}</p>
                  
                  {service.ratingCount > 0 && (
                    <StarRating 
                      rating={averageRating}
                      count={service.ratingCount}
                      priceRange={service.priceRange}
                    />
                  )}
                  
                  {service.address && (
                    <div className="service-address">{service.address}</div>
                  )}
                  
                  <button onClick={() => handleServiceClick(service.id)}>
                    {getButtonText(service.category)}
                  </button>
                </div>
              );
            })}
          </div>
        </main>
      ) : (
        <NoServices 
          pageTitle={pageTitle}
          city={initialCity}
        />
      )}

      <AddressModal
        show={showModal}
        handleClose={() => setShowModal(false)}
        setAddress={setUserAddress}
      />
    </div>
  );
};

export default ServicePage;