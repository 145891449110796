import React, { useEffect, useState } from "react";
import { collection, getDocs, updateDoc, doc, deleteDoc } from "firebase/firestore";
import { db } from "../../firebase"; // Firebase instance
import { useNavigate } from "react-router-dom";
import "./AdminDashboard.css"; // Import the CSS file

const AdminDashboard = () => {
  const [serviceRequests, setServiceRequests] = useState([]);
  const [modificationRequests, setModificationRequests] = useState([]);
  const [activeTab, setActiveTab] = useState("services");
  const navigate = useNavigate();

  useEffect(() => {
    const fetchRequests = async () => {
      try {
        // Fetch pending service requests
        const serviceReqSnap = await getDocs(collection(db, "service_requests"));
        setServiceRequests(serviceReqSnap.docs.map(doc => ({ id: doc.id, ...doc.data() })));

        // Fetch modification requests
        const modReqSnap = await getDocs(collection(db, "modification_requests"));
        setModificationRequests(modReqSnap.docs.map(doc => ({ id: doc.id, ...doc.data() })));
      } catch (error) {
        console.error("Error fetching requests:", error);
      }
    };

    fetchRequests();
  }, []);

  // Approve a service request (change `approved` to `true` in `services` collection)
  const handleApproveService = async (request) => {
    try {
      const serviceRef = doc(db, "services", request.id);
      await updateDoc(serviceRef, { approved: true });

      await deleteDoc(doc(db, "service_requests", request.id));

      setServiceRequests(serviceRequests.filter(req => req.id !== request.id));
    } catch (error) {
      console.error("Error approving service:", error);
    }
  };

  const handleApproveModification = async (request) => {
    try {
      const serviceRef = doc(db, 'services', request.serviceId);
  
      // Apply the changes from modification request
      await updateDoc(serviceRef, {
        approved: false, // Set to false for review
        ...request.updatedFields, // Merge the requested updates
      });
  
      // Remove the request from modification_requests
      await deleteDoc(doc(db, 'modification_requests', request.id));
  
      // Refresh the modification requests list
      setModificationRequests(modificationRequests.filter(mod => mod.id !== request.id));
  
      alert('Modification approved. The service will be reviewed before going live.');
    } catch (error) {
      console.error("Error approving modification:", error);
      alert("Failed to approve modification.");
    }
  };
  
  const handleRejectModification = async (requestId) => {
    try {
      await deleteDoc(doc(db, 'modification_requests', requestId));
      setModificationRequests(modificationRequests.filter(mod => mod.id !== requestId));
      alert("Modification request rejected.");
    } catch (error) {
      console.error("Error rejecting modification:", error);
      alert("Failed to reject modification.");
    }
  };
  
  return (
    <div className="admin-dashboard">
      {/* Header */}
      <div className="dashboard-header">
        <div className="header-container">
          <h1 className="dashboard-title">Admin Dashboard</h1>
          <button 
            onClick={() => navigate('/')}
            className="back-button"
          >
            Back to Home
          </button>
        </div>
      </div>
      
      {/* Main Content */}
      <div className="main-container">
        {/* Tabs */}
        <div className="tabs-container">
          <nav className="tabs-nav">
            <button
              onClick={() => setActiveTab("services")}
              className={`tab-button ${activeTab === "services" ? "active" : ""}`}
            >
              Service Requests 
              {serviceRequests.length > 0 && 
                <span className="count-badge">
                  {serviceRequests.length}
                </span>
              }
            </button>
            <button
              onClick={() => setActiveTab("modifications")}
              className={`tab-button ${activeTab === "modifications" ? "active" : ""}`}
            >
              Modification Requests 
              {modificationRequests.length > 0 && 
                <span className="count-badge">
                  {modificationRequests.length}
                </span>
              }
            </button>
          </nav>
        </div>

        {/* Service Requests */}
        {activeTab === "services" && (
          <div className="content-card">
            <div className="card-header">
              <h2 className="section-title">Pending Service Requests</h2>
              <p className="section-subtitle">Review and approve new service listings</p>
            </div>
            
            {serviceRequests.length > 0 ? (
              <ul className="request-list">
                {serviceRequests.map(request => (
                  <li key={request.id} className="request-item">
                    <div className="request-content">
                      <div className="request-info">
                        <h3 className="request-title">{request.name}</h3>
                        <p className="request-description">{request.description}</p>
                        {request.providerEmail && (
                          <p className="request-submitter">Submitted by: {request.providerEmail}</p>
                        )}
                      </div>
                      <button
                        onClick={() => handleApproveService(request)}
                        className="approve-button"
                      >
                        Approve
                      </button>
                    </div>
                  </li>
                ))}
              </ul>
            ) : (
              <div className="empty-state">
                <p>No pending service requests</p>
              </div>
            )}
          </div>
        )}

        {/* Modification Requests */}
        {activeTab === "modifications" && (
          <div className="content-card">
            <div className="card-header">
              <h2 className="section-title">Pending Modification Requests</h2>
              <p className="section-subtitle">Review and approve changes to existing services</p>
            </div>
            
            {modificationRequests.length > 0 ? (
              <ul className="request-list">
                {modificationRequests.map(request => (
                  <li key={request.id} className="request-item">
                    <div className="request-content">
                      <div className="request-info">
                        <h3 className="request-title">{request.serviceName} - Requested Changes</h3>
                        <p className="request-detail"><strong>Requested By:</strong> {request.providerEmail}</p>
                        {request.changes && (
                          <p className="request-detail"><strong>Changes:</strong> {request.changes.join(', ')}</p>
                        )}
                        <p className="request-detail"><strong>Additional Info:</strong> {request.details}</p>
                      </div>
                      <div className="button-group">
                        <button
                          onClick={() => handleApproveModification(request)}
                          className="approve-button"
                        >
                          Approve
                        </button>
                        <button
                          onClick={() => handleRejectModification(request.id)}
                          className="reject-button"
                        >
                          Reject
                        </button>
                      </div>
                    </div>
                  </li>
                ))}
              </ul>
            ) : (
              <div className="empty-state">
                <p>No pending modification requests</p>
              </div>
            )}
          </div>
        )}
      </div>
    </div>
  );
};

export default AdminDashboard;