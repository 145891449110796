import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowLeft } from '@fortawesome/free-solid-svg-icons';
import { faUser } from '@fortawesome/free-solid-svg-icons';
import { useNavigate } from 'react-router-dom';
import './NavigationHeader.css';

const NavigationHeader = ({ 
  onBackClick, 
  onProfileClick, 
  showBackButton = true, 
  showProfileButton = true 
}) => {
  const navigate = useNavigate();

  const handleBackClick = () => {
    if (onBackClick) {
      onBackClick();
    } else {
      navigate(-1);
    }
  };

  const handleProfileClick = () => {
    if (onProfileClick) {
      onProfileClick();
    } else {
      navigate('/login');
    }
  };

  return (
    <header className="app-navigation-header">
      {showBackButton && (
        <button 
          onClick={handleBackClick} 
          className="app-navigation-header__back-button"
          aria-label="Go back"
        >
          <FontAwesomeIcon icon={faArrowLeft} size="lg" />
        </button>
      )}
      
      {showProfileButton && (
        <button 
          onClick={handleProfileClick} 
          className="app-navigation-header__profile-button"
          aria-label="User profile"
        >
          <FontAwesomeIcon icon={faUser} size="lg" />
        </button>
      )}
    </header>
  );
};

export default NavigationHeader;