import React, { useState, useEffect, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import { auth } from '../../firebase';
import './LandingPage.css';
import Profile from '../../assets/icons/user.png';
import countryCityData from '../../countryCityData.json'; // JSON of countries & cities

function LandingPage() {
  const navigate = useNavigate();
  const [user, setUser] = useState(null);
  const [country, setCountry] = useState(''); // Detected or selected country
  const [city, setCity] = useState(''); // Selected city
  const [continent, setContinent] = useState(''); // Selected continent
  const [cities, setCities] = useState([]); // Available cities for selected country
  const [loadingLocation, setLoadingLocation] = useState(true);

  const temp = ["Toronto", "Vancouver", "Montreal", "Calgary", "Ottawa",
                "Edmonton", "Winnipeg", "Quebec City", "Hamilton", "Kitchener"];
  
  const formRef = useRef(null);

  const motto = "Nourishing Nostalgia";
  const mottoSpans = motto.split("").map((char, index) => (
    <span key={index} style={{ '--i': index }}>{char}</span>
  ));

  useEffect(() => {
    const unsubscribe = auth.onAuthStateChanged((currentUser) => {
      setUser(currentUser);
    });
    return () => unsubscribe();
  }, []);

  // Auto-detect user location
  useEffect(() => {
    const fetchLocation = async () => {
      if (navigator.geolocation) {
        navigator.geolocation.getCurrentPosition(async (position) => {
          const { latitude, longitude } = position.coords;
          try {
            const response = await fetch(
              `https://nominatim.openstreetmap.org/reverse?format=json&lat=${latitude}&lon=${longitude}`
            );
            const data = await response.json();
            const detectedCountry = data.address.country || 'Canada'; // Default to Canada
            setCountry('Canada');
            setCities(countryCityData[country] || []); // Will Fix Later
          } catch (error) {
            console.error("Error fetching location:", error);
            setCountry('Canada');
            setCities(countryCityData['Canada'] || [temp]);
          } finally {
            setLoadingLocation(false);
          }
        }, () => {
          setCountry('Canada');
          setCities(countryCityData['Canada'] || [temp]);
          setLoadingLocation(false);
        });
      } else {
        setCountry('Canada');
        setCities(countryCityData['Canada'] || [temp]);
        setLoadingLocation(false);
      }
    };

    fetchLocation();
  }, []);

  // Update cities when country changes
  useEffect(() => {
    if (country) {
      setCities(countryCityData[country] || [temp]);
      setCity('');
    }
  }, [country]);

  const handleGetStarted = (e) => {
    e.preventDefault();
    if (!continent) {
      alert("Please select a continent.");
      return;
    }

    const continentPath = continent.toLowerCase().replace(/ /g, '-');
    navigate(`/continent/${continentPath}`, { state: { name: user?.displayName || '', city, country, continent } });
  };


  const handleScrollToForm = () => {
    if (formRef.current) {
      formRef.current.scrollIntoView({ behavior: "smooth" });
    }
  };

  const handleLogin = () => {
    navigate('/login');
  };

  const handleLogout = () => {
    auth.signOut().then(() => setUser(null));
  };

  const openProfile = () => {
    navigate('/user-profile');
  };

  return (
    <div className="landing-page">
      {/* Header Section */}
      <div className="login-header">
        {user ? (
          <>
            <button className="login-button" onClick={handleLogout}>Logout</button>
            <img src={Profile} onClick={openProfile} alt="Profile Icon" className="profile-icon" />
          </>
        ) : (
          <button className="login-button" onClick={handleLogin}>Login</button>
        )}
      </div>

      {/* Hero Section */}
      <div className="title-container">
        <img className="title-logo" src="/logo.png" alt="logo" />
        <h1 className="title">Servillia</h1>
        <h2 className="motto">{mottoSpans}</h2>
      </div>

      {/* Updated Selection Form */}
      <form ref={formRef} className="selection-form" onSubmit={handleGetStarted}>
        <h3>Find Services Near You</h3>
        
        <div className="form-group">
          <label htmlFor="country">Your Country</label>
          <select
            id="country"
            value={country}
            onChange={(e) => setCountry(e.target.value)}
            disabled={loadingLocation}
          >
            {Object.keys(countryCityData).map((countryName) => (
              <option key={countryName} value={countryName}>
                {countryName}
              </option>
            ))}
          </select>
        </div>

        <div className="form-group">
          <label htmlFor="city">Your City</label>
          <select
            id="city"
            value={city}
            onChange={(e) => setCity(e.target.value)}
            required
          >
            <option value="" disabled>Select your city</option>
            {cities.length > 0 ? (
              cities.map((cityName) => (
                <option key={cityName} value={cityName}>
                  {cityName}
                </option>
              ))
            ) : (
              <option value="" disabled>No cities available</option>
            )}
          </select>
        </div>

        <div className="form-group continent-group">
          <p className="continent-question">What part of the world makes you feel at home?</p>
          <select 
            id="continent" 
            value={continent} 
            onChange={(e) => setContinent(e.target.value)} 
            required
          >
            <option value="" disabled>Select a continent</option>
            <option value="Africa">Africa</option>
            <option value="Asia">Asia</option>
            <option value="Europe">Europe</option>
            <option value="North America">North America</option>
            <option value="South America">South America</option>
            <option value="Australia">Australia</option>
          </select>
        </div>

        <button className="get-started-button" type="submit">Find Services</button>
      </form>

      {/* Features Section */}
      <div className="features-section">
        <div className="features-grid">
          <div className="feature-card">
            <h3>Discover Services</h3>
            <p>Find culturally specific services like food, hair, and local businesses.</p>
          </div>
          <div className="feature-card">
            <h3>Connect Easily</h3>
            <p>View detailed service pages and reach out to providers seamlessly.</p>
          </div>
          <div className="feature-card">
            <h3>Support Businesses</h3>
            <p>Help local businesses grow while accessing the services you need.</p>
          </div>
        </div>
      </div>

      {/* Rest of the content remains the same */}
      {/* Testimonials Section */}
      <div className="testimonials-section">
        <h2>What Our Users Say</h2>
        <div className="testimonial">
          <p>"Servillia helped me find the best Jollof rice in town!"</p>
          <span>- Grace A.</span>
        </div>
        <div className="testimonial">
          <p>"My business has grown tremendously since joining Servillia."</p>
          <span>- Ahmed B.</span>
        </div>
      </div>

      {/* CTA Section */}
      <div className="cta-section">
        <div className="cta-user">
          <h3>Looking for Services?</h3>
          <button className="cta-button" onClick={handleScrollToForm}>Get Started</button>
        </div>
        <div className="cta-provider">
          <h3>Are You a Service Provider?</h3>
          <button className="cta-button" onClick={() => navigate('/become-service-provider')}>
            List Your Service
          </button>
          <p className="pricing-link" onClick={() => navigate('/pricing')}>View Pricing →</p>
        </div>
      </div>
    </div>
  );
}

export default LandingPage;