import React from "react";
import { useNavigate } from "react-router-dom";
import './PricingPage.css';
import NavigationHeader from "../NavigationHeader";

const PricingPage = () => {
  const navigate = useNavigate();

  // Define regular and early adopter (discounted) prices
  const prices = {
    monthly: {
      regular: 14.99,
      earlyAdopter: 9.99,
    },
    quarterly: {
      regular: 40, // 3 months at regular monthly
      earlyAdopter: 29.97, // 3 months at early adopter rate
    },
    annual: {
      regular: 140, // 12 months at regular monthly
      earlyAdopter: 119.88, // 12 months at early adopter rate
    }
  };

  // Enhanced features with more enticing descriptions
  const features = [
    "✅ Powerful analytics dashboard to track your service performance in real-time",
    "✅ Enhanced visibility to connect with your ideal customers in your local area",
    "✅ Professional service page with customizable layouts that convert visitors to clients",
    "✅ Unlimited content updates to keep your offerings fresh and relevant",
    "✅ VIP early access to exclusive new features before they're publicly released"
  ];

  return (
    <div className="pricing-container">
      <NavigationHeader
       showBackButton={true} 
       showProfileButton={false} 
      />

      <h1>Scale Your Local Business with Servillia</h1>
      <p>Join thousands of local businesses growing their client base and revenue with our all-in-one platform.</p>
      
      <div className="early-adopter-banner">
        <span>🚀 Founding Member Special: Lock in lifetime discounted pricing - limited availability!</span>
      </div>

      <div className="pricing-plans">
        {/* Monthly Plan */}
        <div className="plan monthly">
          <h2>Monthly</h2>
          <p className="plan-subtitle">Flexible month-to-month access</p>
          
          <div className="price-display">
            <p className="original-price"><strong>${prices.monthly.regular}/month</strong></p>
            <p className="discounted-price"><strong>${prices.monthly.earlyAdopter}/month</strong></p>
          </div>
          <p className="savings-text">Save ${(prices.monthly.regular - prices.monthly.earlyAdopter).toFixed(2)}/month (33% off)</p>
          <p className="trial-text">First month FREE</p>
          <button onClick={() => navigate("/subscribe?plan=monthly")}>Get Started</button>
        </div>

        {/* Quarterly Plan */}
        <div className="plan quarterly">
          <h2>Quarterly</h2>
          <p className="plan-subtitle">Our most popular option</p>
          <div className="badge">Best Value</div>
          
          <div className="price-display">
            <p className="original-price"><strong>${prices.quarterly.regular} every 3 months</strong></p>
            <p className="discounted-price"><strong>${prices.quarterly.earlyAdopter} every 3 months</strong></p>
            <p className="per-month-price">Just ${(prices.quarterly.earlyAdopter / 3).toFixed(2)}/month</p>
          </div>
          <p className="savings-text">Save ${(prices.quarterly.regular - prices.quarterly.earlyAdopter).toFixed(2)} (33% off)</p>
          <button onClick={() => navigate("/subscribe?plan=quarterly")}>Select Quarterly</button>
        </div>

        {/* Annual Plan */}
        <div className="plan annual">
          <h2>Annual</h2>
          <p className="plan-subtitle">Maximum savings, one simple payment</p>
          
          <div className="price-display">
            <p className="original-price"><strong>${prices.annual.regular}/year</strong></p>
            <p className="discounted-price"><strong>${prices.annual.earlyAdopter}/year</strong></p>
            <p className="per-month-price">Only ${(prices.annual.earlyAdopter / 12).toFixed(2)}/month</p>
          </div>
          <p className="savings-text">Save ${(prices.annual.regular - prices.annual.earlyAdopter).toFixed(2)}/year (33% off)</p>
          <button onClick={() => navigate("/subscribe?plan=annual")}>Select Annual</button>
        </div>
      </div>

      <p className="all-plans-include">All plans include:</p>
      <div className="pricing-features-section">
        {features.map((feature, index) => (
          <p key={index} className="pricing-feature-item">{feature}</p>
        ))}
      </div>

      {/* Grandfathered Pricing Info */}
      <div className="pricing-info">
        <h3>Founding Member Privilege 🚀</h3>
        <p>Join Servillia now and <strong>lock in your discounted rate for as long as you remain a member!</strong></p>
        <p>Our regular prices will increase as we add more features, but your rate stays the same. Forever.</p>
        <div className="countdown-timer">
          <span>⏳ Limited time offer: Ending soon</span>
        </div>
      </div>
    </div>
  );
};

export default PricingPage;