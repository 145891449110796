import React from 'react';
import { useNavigate } from 'react-router-dom';
import './SubmissionSuccess.css';

const SubmissionSuccess = () => {
  const navigate = useNavigate();

  return (
    <div className="submission-success">
      <div className="success-icon">
        <span className="checkmark">✓</span>
      </div>
      
      <h1>Request Submitted Successfully!</h1>
      <p className="confirmation-message">
        Thank you for your interest in Servillia. We've received your service submission.
      </p>
      
      <div className="next-steps-container">
        <h2>What happens next?</h2>
        
        <div className="step-item">
          <div className="step-number">1</div>
          <div className="step-content">
            <h3>Review Process</h3>
            <p>Our team will review your submission to ensure it meets our quality standards.</p>
          </div>
        </div>
        
        <div className="step-item">
          <div className="step-number">2</div>
          <div className="step-content">
            <h3>Choose a Plan & Start Free Trial</h3>
            <p>Select a subscription plan to activate your service with a <strong>free first month</strong>.</p>
          </div>
        </div>
        
        <div className="step-item">
          <div className="step-number">3</div>
          <div className="step-content">
            <h3>Approval & Visibility</h3>
            <p>Once approved and subscribed, your service will become visible to potential customers.</p>
          </div>
        </div>
      </div>
      
      <div className="important-note">
        <div className="note-icon">!</div>
        <p>
          <strong>Important:</strong> Your subscription will only be charged after approval. 
          If your service is not approved, your subscription will be automatically canceled with no charges.
        </p>
      </div>
      
      <p className="email-notification">
        We've sent confirmation details to your email. Please check your inbox (and spam folder) for further instructions.
      </p>
      
      <div className="action-buttons">
        <button 
          onClick={() => navigate('/pricing')} 
          className="primary-button"
        >
          Choose a Plan & Start Free Trial
        </button>
        
        <button 
          onClick={() => navigate('/')} 
          className="secondary-button"
        >
          Return to Home
        </button>
      </div>
    </div>
  );
};

export default SubmissionSuccess;