// UserDashboard.js
import React, { useEffect, useState } from 'react';
import { getAuth, onAuthStateChanged, deleteUser, updatePassword, updateProfile, EmailAuthProvider, reauthenticateWithCredential  } from 'firebase/auth';
import { logout } from '../../authService'; // Update the path accordingly
import { useNavigate } from 'react-router-dom';
import { doc, getDoc, collection, query, where, getDocs } from 'firebase/firestore'; // Firestore methods
import { db } from '../../firebase'; // Ensure you have Firebase initialized
import './UserProfile.css';  // Add your styling here
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowLeft } from '@fortawesome/free-solid-svg-icons';


const UserProfile = () => {
  const [user, setUser] = useState(null);
  const [newName, setNewName] = useState('');
  const [currentPassword, setCurrentPassword] = useState(''); // For re-authentication
  const [newPassword, setNewPassword] = useState('');
  const [favorites, setFavorites] = useState([]); // State to store favorite services
  const [favoriteServices, setFavoriteServices] = useState([]); // To store the full service data
  const [isProvider, setIsProvider] = useState(false);
  const [providerId, setProviderId] = useState([]);
  const [loadingProviderStatus, setLoadingProviderStatus] = useState(true);
  const [isAdmin, setIsAdmin] = useState(false);
  const navigate = useNavigate();
  const auth = getAuth();

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, async (currentUser) => {
      if (currentUser) {
        setUser(currentUser);
        fetchFavorites(currentUser.uid);
        await checkProviderStatus(currentUser.uid);
        await checkAdminStatus(currentUser.uid);
      }
    });

    return () => unsubscribe();
  }, []);

  const checkProviderStatus = async (userId) => {
    try {
      const userDocRef = doc(db, "users", userId);
      const userDoc = await getDoc(userDocRef);

      if (userDoc.exists()) {
        const userData = userDoc.data();
        setIsProvider(!!userData.isProvider);
        setProviderId(userData.isProvider ? userId : '');
      }
    } catch (error) {
      console.error("Error checking provider status:", error);
    } finally {
      setLoadingProviderStatus(false);
    }
  };

  const checkAdminStatus = async (userId) => {
    try {
      const adminDocRef = doc(db, "admins", userId); // Check if user exists in admins collection
      const adminDoc = await getDoc(adminDocRef);

      if (adminDoc.exists()) {
        setIsAdmin(true); // User is an admin
      }
    } catch (error) {
      console.error("Error checking admin status:", error);
    }
  };


  const handleLogout = async () => {
    try {
      await logout(); // Use the imported logout function
      navigate(-1); // Navigate to to prev page after logging out
    } catch (error) {
      console.error("Error logging out: ", error);
    }
  };

  // Function to fetch the user's favorites
  const fetchFavorites = async (userId) => {
    const userRef = doc(db, 'users', userId);
    const userSnap = await getDoc(userRef);

    if (userSnap.exists()) {
      const userData = userSnap.data();
      setFavorites(userData.favorites || []);
      fetchFavoriteServices(userData.favorites || []); // Fetch the actual service details
    }
  };

  // Function to fetch detailed service info based on favorite IDs
  const fetchFavoriteServices = async (favoriteIds) => {
    const services = [];
    for (const serviceId of favoriteIds) {
      const serviceRef = doc(db, 'services', serviceId);
      const serviceSnap = await getDoc(serviceRef);
      if (serviceSnap.exists()) {
        services.push({ id: serviceId, ...serviceSnap.data() });
      }
    }
    setFavoriteServices(services); // Store detailed service info
  };


  const handleDeleteAccount = () => {
    const auth = getAuth();
    const user = auth.currentUser;

    if (user) {
      if (window.confirm("Are you sure you want to delete your account? This action cannot be undone.")) {
        deleteUser(user).then(() => {
          navigate('/'); // Navigate to homepage or appropriate page after account deletion
        }).catch((error) => {
          console.error("Error deleting account: ", error);
          alert("Failed to delete account. Please try again later.");
        });
      }
    }
  };

  const handleUpdatePassword = async () => {
    if (!currentPassword || !newPassword) {
      alert("Please enter your current password and the new password.");
      return;
    }

    const auth = getAuth();
    const user = auth.currentUser;
    const credential = EmailAuthProvider.credential(
      user.email,
      currentPassword
    );

    try {
      // Re-authenticate the user
      await reauthenticateWithCredential(user, credential);

      // If re-authentication is successful, update the password
      await updatePassword(user, newPassword);
      alert("Password updated successfully!");
    } catch (error) {
      console.error("Error updating password:", error);
      alert("Failed to update password. Please try again.");
    }
  };

  const handleViewProviderDash = () => {
    navigate(`/provider-dashboard/${providerId}`);
  }

  const handleViewAdminDash = () => {
    navigate('/admin-dashboard');
  }

  return (
    <div className="user-profile">
      {/* Back Icon */}
      <FontAwesomeIcon 
        icon={faArrowLeft} 
        className="back-icon" 
        onClick={() => navigate(-1)} 
      />
      {user ? (
        <>
          {/* Profile Header */}
          <h1>Welcome, {user.displayName || user.email}!</h1>
          {/* <p>Email: {user.email}</p>
          {user.displayName && <p>Name: {user.displayName}</p>} */}

          {loadingProviderStatus ? (
                <p>Loading provider status...</p>
              ) : (
                <>
                  {isProvider && (
                    <button onClick={handleViewProviderDash}>
                      View Provider Dashboard
                    </button>
                  )}
                  {isAdmin && (
                    <button onClick={handleViewAdminDash}>
                      View Admin Dashboard
                    </button>
                  )}
                </>
              )}

          {/* Favorite Services Section */}
          <h2>Your Favorite Services</h2>
          <div className="favorites-list">
            {favoriteServices.length > 0 ? (
              favoriteServices.map((service) => (
                <div key={service.id} className="favorite-service">
                  <img
                    src={service.banner || 'https://via.placeholder.com/150'}
                    alt={service.name}
                    className="favorite-service-image"
                  />
                  <h3>{service.name}</h3>
                  <button onClick={() => navigate(`/service/${service.id}`)}>
                    View Details
                  </button>
                </div>
              ))
            ) : (
              <p>You have no favorite services yet.</p>
            )}
          </div>
  
          {/* Update Password Section */}
          <h2>Change Password</h2>
          <input
            type="password"
            placeholder="Current Password"
            value={currentPassword}
            onChange={(e) => setCurrentPassword(e.target.value)}
          />
          <input
            type="password"
            placeholder="New Password"
            value={newPassword}
            onChange={(e) => setNewPassword(e.target.value)}
          />
          <button
            className="update-password-button"
            onClick={handleUpdatePassword}
          >
            Update Password
          </button>
  
          {/* Profile Buttons */}
          <div className="profile-buttons">

            <button className="go-back-button" onClick={() => navigate(-1)}>
              Go Back
            </button>
            <button className="log-out-button" onClick={handleLogout}>
              Log Out
            </button>
            <button className="delete-button" onClick={handleDeleteAccount}>
              Delete Account
            </button>
          </div>
        </>
      ) : (
        <p>Loading...</p>
      )}
    </div>
  );
  
};

export default UserProfile;
